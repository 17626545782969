import React from 'react'
import ReactSwitch from 'react-switch';
import { GiMoon } from 'react-icons/gi';

import addMotion from '../../utils/addMotion';
import styles from './Switch.module.css';

const Switch = ({theme, toggleTheme, variant}) => {
  return (
    <div
      className={`${styles.switch} dropShadow
        ${
          variant === 'displaySmall' ? 
          styles.displaySmall : variant === 'displayLarge' ?
          styles.displayLarge : null
        } 
      `}
    >
      {addMotion([
        <label 
          className={`${styles.label} textNav`}
          onClick={toggleTheme}
        >
          <GiMoon className='boxShadow' size={20} />
        </label>
      ])}
      {addMotion([
        <div>
          <ReactSwitch 
            onChange={toggleTheme}
            checked={theme === 'dark'}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor='#00ff00'
            offColor='#008080'
            onHandleColor='#303030'
          />
        </div>
      ])}
    </div>
  )
}

export default Switch