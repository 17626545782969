const navItems = [
  {
    url: '/',
    name: 'Home',
  },
  {
    url: '/about',
    name: 'About',
  },
  {
    url: '/blog',
    name: 'Blog',
  },
  {
    url: '/projects',
    name: 'Projects',
  },
];

export default navItems;
