import React from 'react'
import { motion } from 'framer-motion';
import ImageOverlay from '../../components/ImageOverlay/ImageOverlay';
import Quotes from '../../components/Quotes/Quotes';
import quoteArray1 from '../../content/quoteArray1';
import profilepic from '../../assets/images/profileDrawing.gif';
import fitness from '../../assets/images/fitness.jpg';
import shiba from '../../assets/images/shiba.jpg';
import sti from '../../assets/images/sti.jpg';
import theforce from '../../assets/images/theforce.jpg';
import whistler from '../../assets/images/whistler.jpg';
import yoda from '../../assets/images/yoda.jpg';
// images are 600x450

import styles from './About.module.css';

const addShift = (image, alt, html, shiftDelay) => {
  const delay = shiftDelay ? 0.5 : 0.125;
  return (
    <motion.div
      className='my-4'
      initial={{ opacity: 0, y: -100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: delay, duration: 0.5 }}
    >
      <ImageOverlay
        image={image}
        alt={alt}
        html={html}
      />
    </motion.div>
  )
}

const About = ({theme}) => {
  return (
    <motion.div
      className={`${styles.aboutWrapper} text`}
      initial={{ y: 1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 1 }}
    >
      <div className={`${styles.about} mx-auto`}>
        <img className={`${styles.profilepic} img-fluid opacity-75 sticky-top`} src={profilepic} alt='Pete wearing a blue tuxedo' />
        <h2 className={`${styles.h2} text-center fs-1 fw-light py-5 text`}>About Me</h2>
        <section className={`${styles.section} mb-5 py-5`}>
          <p className={`${styles.p} text-white`}>
            Hello,
          </p>
          <p className={`${styles.p} text-white`}>
            My name is <span className='fw-normal'>Pete</span>, and I grew up in the 90s. I fondly remember multi-sided die, Bulletin Board Systems, Sierra games, and AOL CDs.
          </p>
          <p className={`${styles.p} text-white`}>
            "Medicine is my destiny," or so I was told. I have a <span className='fw-normal'>Bachelor's Degree</span> in Biological Sciences, and my <span className='fw-normal'>stationInLife</span> was in pharmaceutical engineering for two decades. 
          </p>
          <p className={`${styles.p} text-white`}>
            In pursuing my passion, I have completed a full stack coding bootcamp at MIT xPRO with a <span className='fw-normal'>4.0 GPA</span>, and have launched multiple business websites as a freelance web developer.
          </p>

          <p className={`${styles.p} text-white`}>
            I am a <span className='fw-normal'>fast learner</span> and <span className='fw-normal'>extremely passionate</span> about <span className='fw-normal'>responsive web design</span>.
          </p>
          <p className={`${styles.p} text-white`}>
            Thank you for visiting my portfolio and taking the time to read this.
          </p>
          <p className={`${styles.p} text-white`}>
            Best Regards,<br /><span className='fw-normal'>Pete Chu</span>
          </p>
          <hr className={`my-5`} />
          <p className={`${styles.p} textSilver fontMono my-0`}>
            stationInLife.replace(
          </p>
          <p className={`${styles.p} ${styles.pReplace} textSilver fontMono my-0`}>
            "Pharmaceutical Engineer",
          </p>
          <p className={`${styles.p} ${styles.pReplace} textSilver fontMono my-0`}>
            "Software Developer"
          </p>
          <p className={`${styles.p} textSilver fontMono my-0`}>
            );
          </p>
        </section>
      </div>
      <div className={`${styles.contrast} bgContrastNoClip`}>
        <div className={`${styles.row} row mx-auto pt-5`}>
          <div className={`${styles.col} col-sm-6`}>
            {addShift(
              yoda, 
              'Jim, a former friend and mentor of Pete.  Jim is photoshopped to look like Yoda.',
              [
                <h4 className={`${styles.h4}`}>Pharmaceuticals was serious business, but we had fun too!</h4>,
                <p className={`${styles.imageP}`}>
                  Meet one of my mentors, "Yoda Jim" (and a sampling of my Photoshop work).
                </p>,
              ],
              false
            )}
          </div>
          <div className={`${styles.col} col-sm-6`}>
            {addShift(
              shiba, 
              'Pete and his late and beloved Shiba Inu. He had her before they became a meme!',
              [
                <h4 className={`${styles.h4}`}>Dogs &#62; cats, but I like any animal that does not try to eat me</h4>,
                <p className={`${styles.imageP}`}>
                  This is my late and beloved Shiba Inu, I had her before they became a meme!
                </p>,
              ],
              true
            )}
          </div>
        </div>
        <div className={`${styles.row} row pb-5 mx-auto`}>
          <div className={`${styles.col} col-sm-6`}>
            {addShift(
              sti, 
              'Pete driving a black 2005 Subaru WRX STi at the New Jersey Motorsports Park.  This was at an HPDE event.',
              [
                <h4 className={`${styles.h4}`}>High Performance Driver Education</h4>,
                <p className={`${styles.imageP}`}>
                  This was my 2005 Subaru WRX STi. I drove around in circles with a group of friends.
                </p>,
              ],
              false
            )}
          </div>
          <div className={`${styles.col} col-sm-6`}>
            {addShift(
              whistler, 
              'Pete snowboarding at Whistler Blackcomb, BC. He is sitting in the snow with a scenic sunny mountain backdrop.',
              [
                <h4 className={`${styles.h4}`}>Snowboarding is my winter hobby</h4>,
                <p className={`${styles.imageP}`}>
                  Some of the best times of my life were riding down mountains with friends.
                </p>,
              ],
              true
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.row} row pt-5 mx-auto`}>
        <div className={`${styles.col} col-sm-6`}>
          {addShift(
            fitness, 
            'Pete is also an ISSA certified personal trainer, he does this as a hobby.',
            [
              <h4 className={`${styles.h4}`}>Fitness and nutrition</h4>,
              <p className={`${styles.imageP}`}>
                I pick up heavy things and put them back down, some call it "resistance training."  I'm also a certified trainer (hobby).
              </p>,
            ],
            false
          )}
        </div>
        <div className={`${styles.col} col-sm-6`}>
          {addShift(
            theforce, 
            'Pete and his wife on their wedding day.  His wife is lifting Pete up by his throat using The Force!',
            [
              <h4 className={`${styles.h4}`}>"This is the way."</h4>,
              <p className={`${styles.imageP}`}>
                I may have also married a Sith Lord!
              </p>,
            ],
            true
          )}
        </div>
      </div>
      
      <Quotes theme={theme} array={quoteArray1} />
    </motion.div>
  )
}

export default About