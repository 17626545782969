import { motion } from 'framer-motion';
import profilepic from '../../../assets/images/profile900w.gif';
import styles from './RowIntro.module.css';

const RowIntro = () => {
  return (
    <motion.div
      className={`${styles.hello} row mx-auto`}
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <img
        src={profilepic}
        className={`${styles.profilepic} col-sm-6 img-fluid opacity-75 dropShadow mx-auto`}
        alt='Pete wearing a blue tuxedo'
      />
      <div className={`${styles.intro} col-sm-6 m-auto`}>
        <p className={`${styles.p} fw-lighter textSecondary fs-5`}>Hello,</p>
        <h3 className={`${styles.h3} fst-italic text`}>My name is Pete.</h3>
        <h5 className={`${styles.h5} fst-italic fw-light fs-4 ${styles.span}`}>Welcome to my portfolio!</h5>
      </div>
    </motion.div>
  )
}

export default RowIntro;
