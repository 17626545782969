import ArticleFooter from '../../components/Articles/ArticleFooter/ArticleFooter';
import ArticleImage from '../../components/Articles/ArticleImage/ArticleImage';
import article from '../../assets/articles/article.jpg';
import standupMeeting from '../../assets/articles/standupMeeting.jpg';
import creative from '../../assets/articles/creativeMind.jpg';

const pStyles = {
  lineHeight: '2rem',
}
const aStyles = {
  color: 'cornflowerblue',
}

const linkedin = `https://www.linkedin.com/posts/codebypete_8-weeks-as-a-43-year-old-software-engineering-activity-6963185079586013184-Vl8b`;
const twitter = `https://twitter.com/codeTrackLift/status/1557421003281760263`;
const urlArticle = `https://www.forbes.com/sites/forbesdigitalgroup/2022/08/10/8-weeks-as-a-43-year-old-software-engineering-intern/`;

const article4 =  {
  title: 'Eight Weeks as a 43 Year Old Software Engineering Intern',
  description: `Below is the article that is published on Forbes.com.  It is a combination of my three week recap and UI experiment articles.`,
  image: article,
  alt: 'Software engineer hard at work writing legible code, colllaborating and communicating well with his teammates.',
  date: 'Aug 11, 2022',
  updated: '',
  html: [
    <p className={`fw-bold`} style={pStyles}>Greetings!</p>,
    <p className={`pb-4`} style={pStyles}>Below is the article that is published on Forbes.com.  It is a combination of my three week recap and UI Experiment articles. Please see this <a href={urlArticle} target='_blank' rel='noreferrer' style={aStyles}>link</a> for the original article on Forbes.com.</p>,
    <hr />,
    <ArticleImage
      src={article}
      alt={`Software engineer hard at work writing legible code, colllaborating and communicating well with his teammates.`}
      wide
    />,   
    <p style={pStyles}>During the second trimester of my coding bootcamp, the career coaches connected me with a referral for an Engineering Internship with Forbes Media. I wasn't expecting to get called for the interview, but was pleasantly surprised when it happened.</p>,
    <p style={pStyles}>Everybody I met at the interview was extraordinarily nice, but they did challenge me with technical JavaScript questions. In hindsight, there were several areas where I could have articulated a better answer. However, I was very fortunate, as the Frontend Engineering team decided to give me a chance!</p>,
    <p style={pStyles}>It all became very real when I got my equipment delivered and set up. After getting local administrator privileges, I started feeling like a developer again! This feeling did not last long as I immediately slipped back into imposter syndrome when I couldn't install the necessary packages for my repository.</p>,
    <p style={pStyles}>My mentor came to the rescue and helped remedy the issue (missing settings). From that point on, I was able to use git, clone the appropriate repos, install the packages, and get my local development environment up and running! 😊</p>,
    <ArticleImage
      src={standupMeeting}
      alt={`Software engineers participating in stand-up meetings.`}
    />,   
    <p style={pStyles}>The next major milestone was attending and participating in team stand-ups and meetings. It is quite the privilege to be able to listen in on these discussions, as they helped fill in missing tech vocabulary. During one of the meetings, it was mentioned that a new component was needed, specifically a button with a nested image. I got excited, as I had done something similar on my portfolio. However, I did not have the confidence to bring it up in the meeting.</p>,
    <p style={pStyles}>After wrapping up, I quietly messaged my mentor and showed him a screenshot of my GitHub Readme button. My mentor and the Frontend Engineering Manager discussed, and decided to assign me my first ticket! Everything I have developed thus far has been coded in a silo. So working on a large and complex codebase with a group was quite the eye-opener!</p>,
    <p style={pStyles}>After making the button and verifying that it looks and functions as intended in my local development environment, I had to push it to the GitHub repository and make a pull request (PR). Imposter syndrome was kicking in hard at this point, as I was about to expose my coding insecurities to the whole world (or so it felt)! I reached out to a junior developer on the team and shared my files with him, hoping for some reassurance. He gave me the thumbs up, then encouraged me to push the code, and so I did.</p>,
    <p style={pStyles}>Folks had warned me that your first PR as a developer could result in a lot of comments. 55 comments later, my button component could now be dynamically rendered with SVGs, text, click functionality, and even included a suite of unit-tests! Each comment pointed out weaknesses in my code, like redundant and inefficient code, as well as failure to have good test coverage. This set a good back and forth tempo with the experienced software engineers on the team.</p>,
    <p style={pStyles}>My first three weeks with Forbes validates my decision to leave my pharmaceutical career to pursue my passion. I enjoy every minute of the work, learning each new technology feels exciting and rewarding, and the team is very warm and welcoming. Maybe this will change if I ever lose the 'intern' status; however, I will not worry about it right now as I only have a limited time with this team.</p>,
    <p style={pStyles}>One month into the internship… During a department meeting, the topic of the UI Showcase came up. All the members of the Frontend Engineering team were encouraged to make contributions, as well as recruit developers from other departments.</p>,
    <ArticleImage
      src={creative}
      alt={`Software engineer hard at work writing legible code, colllaborating and communicating well with his teammates.`}
      wide
    />,   
    <p style={pStyles}>What do I make? My mind was an empty cup, which can work great for learning and a 'student-mentality.' For creativity... Not so much. So, I started by establishing an objective.</p>,
    <p style={pStyles}>The 'purpose' of my UI Experiment, would be to re-imagine an existing feature from Forbes.com. I decided to target image carousels, and used a personal project component as a starting point. The first draft was functional, but looked like a website from the early 2000's.</p>,
    <p style={pStyles}>That's when I started researching React animation libraries. The library I chose is well supported, has many weekly downloads, and is relatively simple to implement. It's almost as easy as adding 'motion.' to your JSX tags, then defining the desired effect as properties in the opening tag.</p>,
    <p style={pStyles}>Two months into the internship… I have had the privilege to work on over two dozen pull requests. This included creating unit-test suites, de-bugging, addressing code quality issues, and creating new React components. I had the opportunity to make contributions to the UI Showcase, participate in Performance Testing to support the React Migration, and help draft the report that was presented to management.</p>,
    <p style={pStyles}>I have actively participated in stand-up meetings, as well as pair-program with frontend engineers. With the Forbes Frontend Engineering team investing in me, I feel like I am learning at an incredible pace. I only hope that I have been able to return the favor by adding value with my contributions.</p>,
    <p style={pStyles}>Thank you very much Forbes, for turning a 20+ year dream into a reality!</p>,
    <ArticleFooter
      linkedin={linkedin}
      twitter={twitter} 
    />,
  ],
};

export default article4;
