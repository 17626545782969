import { useScroll, motion, useTransform } from 'framer-motion';
import ScrollDown from '../ScrollDown/ScrollDown';
import Top from '../Top/Top';
import programmer from '../../assets/images/programmer.gif';
import programmerCss from '../../assets/images/programmer-css.gif';
import programmerPower from '../../assets/images/programmer-power.gif';
import programmerHtml from '../../assets/images/programmer-html.gif';
import programmerJs from '../../assets/images/programmer-js.gif';
import programmerSettings from '../../assets/images/programmer-settings.gif';
import { injectHeaderH1, injectHeaderH3, injectHeaderH4 } from '../../content/injectHeader';
import styles from './Header.module.css';

const Header = () => {
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 1000], [0, 100]);
  const scale = useTransform(scrollY, [0, 1000], [1, 0.8]);

  return (
    <motion.header
      className={`${styles.headerWrapper} bgBold`}
      initial={{ opacity: 0, y: -1000 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -1000 }}
      transition={{ duration: 1 }}
    >
      <div className={`${styles.headerContent} pb-5`}>
        <Top />
        <div className={`${styles.row} container-fluid row`}>
          <div className={`${styles.left} col-sm-5 my-auto`}>
            <h3 className={`${styles.h3} my-4 pb-3`}>
              { injectHeaderH3() }
            </h3>
            <h1 className={`${styles.h1} textShadow text-white`}>
              { injectHeaderH1() }
            </h1>
            <h4 className={`${styles.h4} textSilver textShadow fw-lighter text-center fst-italic`}>
              { injectHeaderH4() }
            </h4>
          </div>
          
          <motion.div
            className={`${styles.right} d-flex col-sm-7 pt-5`}
            style={{ y: y, scale: scale }}
          >
            <img 
              src={programmer}
              className={`${styles.hero} ${styles.programmer} img-fluid dropShadowBig`}
              alt='Illustration of Pete working exceptionally hard at developing a responsive web application.'
            />
            <img 
              src={programmerCss}
              className={`${styles.hero} ${styles.float} ${styles.float1} img-fluid dropShadowBig`}
              alt='Floating token of representing CSS, Cascading Style Sheets.'
            />
            <img 
              src={programmerPower}
              className={`${styles.hero} ${styles.float} ${styles.float2} img-fluid dropShadowBig`}
              alt='Floating token of a power symbol.'
            />
            <img 
              src={programmerSettings}
              className={`${styles.hero} ${styles.float} ${styles.float3} img-fluid dropShadowBig`}
              alt='Floating token of a gear symbol representing settings.'
            />
            <img 
              src={programmerHtml}
              className={`${styles.hero} ${styles.float} ${styles.float4} img-fluid dropShadowBig`}
              alt='Floating token representing HTML, Hyper Text Markup Language.'
            />
            <img 
              src={programmerJs}
              className={`${styles.hero} ${styles.float} ${styles.float5}  img-fluid dropShadowBig`}
              alt='Illustration of Pete working exceptionally hard at developing a responsive web application.'
            />
          </motion.div>
        </div>
        <ScrollDown />
      </div>
    </motion.header>
  )
}

export default Header;
