import ButtonLine from '../../ButtonLine/ButtonLine';
import styles from './ArticleThumb.module.css';

const ArticleThumb = ({articleList, onClick, theme}) => {
  const articles = Object.keys(articleList).map((i) => {

    return (
      <div
        key={i}
        className={`${styles.col} col-sm-6 col-md-4 my-5 mx-auto`} 
      >
        <div>
          <div className={`${styles.imageWrapper} w-100 text-center`}>
            <img
              src={articleList[i].article.image}
              alt={articleList[i].article.alt}
              className={`img-fluid`}
            />
          </div>
          <h5 className={`mt-4`}>
            {articleList[i].article.title}
          </h5>
          <span className={`${styles.span}`}>
            {articleList[i].article.date}
          </span>
          <p className={`my-2 fw-lighter textContrast`}>
            {articleList[i].article.description}
          </p>
        </div>
        <div className={`mx-auto`}>
          <ButtonLine
            type={'button'}
            onClick={() => onClick(articleList[i].article)}
            text={'READ MORE'}
            theme={theme}
          />
        </div>
      </div>
    )
  })
  return articles;
}

export default ArticleThumb;
