import { BsCloudDownload } from 'react-icons/bs';

import ButtonLine from '../ButtonLine/ButtonLine';
import Info from '../Info/Info';
import Form from '../Form/Form';
import resume from '../../assets/resumeByPete.pdf'
import styles from './Contact.module.css';

export default function Contact({theme}) {
  return (
    <div id='contact' className={`${styles.contactWrapper}`}>
      <div className={`${styles.contact} text-white row container-fluid`}>
        <h3 className={`${styles.h3} text-center mb-5 my-3`}>Contact Me</h3>
        <div className={`${styles.info} col-md-6`}>
          <div className='mx-auto'>
            <ButtonLine
              type={`aHtml`}
              to={resume}
              html={[
                <BsCloudDownload className={`${styles.download}`} size={25} />,
                ` RESUME`
              ]}
              textWhite
            />
          </div>
          <Info />
        </div>
        
        <div className='container-fluid text-center col-md-6'>
          <Form />
        </div>
      </div>
    </div>
  )
};
