import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, useScroll } from 'framer-motion';
import { MdOutlineClose } from 'react-icons/md';
import { FiMenu } from 'react-icons/fi';

import Switch from '../Switch/Switch';
import styles from './NavBar.module.css'
import navItems from '../../content/navItems';
import useWindowDimensions from '../../utils/windowDimensions';

const activeStyle = {
  color: 'lime',
};

const showPositionNarrow = 800;
const showPositionNormal = 875;
const showPositionWide = 1000;

const injectListItems = (toggleOpen) => {
  const listItems = [];
  navItems.map((li, i) => listItems.push(
    <li key={i}>
      <NavLink 
        className={`${styles.a} textShadow textNav`}
        to={li.url}
        onClick={() => toggleOpen()}
        style={({ isActive }) => isActive ? activeStyle : undefined}
      >
        {li.name}
      </NavLink>
    </li>
  ))
  return listItems;
}

const NavBar = ({theme, toggleTheme }) => {
  const { width } = useWindowDimensions();
  const [openMenu, setOpenMenu] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const { scrollY } = useScroll();
  useEffect(() => {
    return scrollY.onChange((latest) => {
      setScrollPos(latest);
    })
  }, [scrollPos, scrollY])

  const toggleOpen = () => {
    setOpenMenu((openStatus) => (openStatus === true ? false : true));
  };

  const injectMotionButton = ({className, onClick, content}) => {
    return (
      <motion.button
        whileHover={{
          scale: 1.1,
          transition: {
            duration: 0.25,
            type: 'spring',
            stiffness: 250,
          },
        }}
        whileTap={{ scale: 0.9 }}
         className={className}
         onClick={onClick}
       >
        {content}
      </motion.button>
    )
  }

  const scrollToBottom = () => {
    toggleOpen();
    setTimeout(() => document.querySelector(`#contact`).scrollIntoView({ behavior: "smooth" }), 50);
  }

  const showPosition = () => {
    return width > 1200 ? 
      showPositionWide : width > 650 ? 
      showPositionNormal : showPositionNarrow;
  }

  return (
    <motion.div 
      id='nav'
      className={`
        ${styles.navWrapper}
        ${ scrollPos > showPosition() ? styles.boxShadow : '' }
        sticky-top bgBold
      `}
      initial={{ opacity: 0, y: -200 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -200 }}
      transition={{ duration: 0.5 }}
    >
      <nav
        className={ openMenu ? 
          `${styles.openNav}
          ${styles.nav}`  : 
          styles.nav }
      >
        {injectMotionButton({
          'className': styles.close,
          'onClick': () => toggleOpen(),
          'content': <MdOutlineClose color='white' size={25} />,
          })
        }
        <ul className={styles.ul}>
          <Switch theme={theme} toggleTheme={toggleTheme} variant={'displaySmall'} />
          { injectListItems(toggleOpen, scrollPos) }
          <li>
            <div 
              className={`
                ${styles.button} 
                ${ scrollPos > showPosition() ? null : 'textNav' } textShadow
              `}
              onClick={() => scrollToBottom()}
            >
              CONTACT
            </div>
          </li>
          <Switch theme={theme} toggleTheme={toggleTheme} variant={'displayLarge'} />
        </ul>
      </nav>
      {injectMotionButton(
        {
          'className': `${ openMenu ? 'opacity-0' : null }
            ${ theme === 'light' ? styles.menuLight : null }
            ${styles.menu}
          `,
          'onClick': () => toggleOpen(),
          'content': <FiMenu className={`${styles.menuIcon} textContrast`} color={ scrollPos > showPosition() - 100 ? '' : 'gray' } size={25} />,
        })
      }
    </motion.div>
  )
}

export default NavBar;
