import Carousel from 'react-bootstrap/Carousel';
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs';

import styles from './Quotes.module.css';

import addMotion from '../../utils/addMotion';

const carouselStyle = {
  zIndex: 1,
  margin: '2rem 0 1rem 0',
  backgroundColor: 'transparent'
}

const injectCite = () => {
  return (
    <cite title="Source Title" className="textSilver textShadow" style={{opacity:0.6}}>
      quote<span style={{color:'#00857c'}}>By</span><span style={{color:'lime'}}>Pete</span>
    </cite>
  )
}

const injectCarouselItems = (array) => {
  const quotes = array.map((quote, i) => {
    return (
      <Carousel.Item
        className={`text-center`}
        key={i}
      >
        <h3
          className={`${styles.quote} px-5 textShadowBlack text opacity-50 fw-light`}
          style={{fontStyle:'italic'}}
        >
          {quote}
        </h3>
        <figcaption className={`${styles.cite} blockquote-footer text-end mt-3`}>
          {injectCite()}
        </figcaption>
      </Carousel.Item>
    )
  })
  return quotes;
}

export default function Quotes({theme, array}) {
  return (
    <div className={`${styles.carouselWrapper}`}>
      <Carousel
        className={`${styles.carousel} carousel slide mx-auto`} 
        data-bs-ride="carousel" 
        indicators={false} 
        style={carouselStyle}
        nextIcon={addMotion([<BsArrowRightCircle color={theme === 'dark' ? 'silver' : 'dimgray' } size={30} />])}
        prevIcon={addMotion([<BsArrowLeftCircle color={theme === 'dark' ? 'silver' : 'dimgray' } size={30} />])}
        touch
        fade
      >
        {injectCarouselItems(array)}
      </Carousel>
    </div>
  );
};
