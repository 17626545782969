import { motion } from 'framer-motion';

import Recommends from '../../components/Recommends/Recommends';
import RowIcons from '../../components/Rows/RowIcons/RowIcons';
import RowIntro from '../../components/Rows/RowIntro/RowIntro';
import RowMit from '../../components/Rows/RowMit/RowMit';
import RowTech from '../../components/Rows/RowTech/RowTech';
import RowUdel from '../../components/Rows/RowUdel/RowUdel';

import styles from './Home.module.css';

const Home = ({theme}) => {
  return (
    <motion.div
      className={`${styles.home} text w-100 container-fluid`}
      initial={{ y: 1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 1 }}
    >
      <RowIntro />
      <RowIcons />
      
      <div className={`${styles.rowWrapper} bgContrast`}>
        <RowMit theme={theme} />
        <div className={`${styles.rowTech}`}>
          <h4 className='text-center fw-lighter'>Technologies | Skills</h4>
          <RowTech />
        </div>
        <RowUdel theme={theme} />
      </div>
     
      <div className={`${styles.professional} text-center`}>
        <h3 className={`fw-lighter text pt-5 pb-4 mt-5`}>Professional recommendations below.</h3>
        <p className={`fw-lighter textGray`}>
          Read the entire recommendation on
          <a
            href='https://www.linkedin.com/in/codebypete/details/recommendations/'
            target='_blank'
            rel='noreferrer'
            className={`cornflowerBlue`}
            style={{paddingLeft: '0.25rem'}}
          >
             LinkedIn
          </a>.
        </p>
        <div className={`${styles.recommendsWrapper} bgBlue`}>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`${styles.recommends}`}
          >
            <Recommends theme={theme}/>
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
};

export default Home;
