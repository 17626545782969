import { motion } from 'framer-motion';
import Switch from '../Switch/Switch';
import profile from '../../assets/images/profile900w.gif';
import styles from './Modal.module.css'

const fadeIn = {
  initial: {
    y: '-1000',
    opacity: 0
  },
  animate: {
    y: '0',
    opacity: 1,
    transition: { delay: 1.25, duration: 0.75 }
  },
  exit: {
    y: '-1000',
    opacity: 0, 
    transition: {
      delay: 0,
      duration: 0.75
    }
  }
};

export default function Modal({ handleClose, article, theme, toggleTheme }) {
  const scrollToTop = () => setTimeout(() => document.querySelector(`#spacer`).scrollIntoView({ block: "center", behavior: "smooth" }));
  
  const renderCloseButton = () => 
    <button 
      onClick={() => {setTimeout(scrollToTop()); handleClose()}} 
      className={`
        ${styles.button} 
        ${ theme === 'light' ? 'text-black' : 'text-white'}
        my-4
      `}
    >
      CLOSE
    </button>;

  return (
    <motion.div
      className={`${styles.backdrop} container-fluid`}
      initial={{ opacity: 0, y: 1000 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 1000 }}
      transition={{ duration: 0.75 }}
    >
      <motion.div
        className={`
          ${styles.modal} ${theme === 'dark' ? 'text-white' : null }
          mx-auto container-fluid
        `}
        variants={fadeIn}
        initial='initial'
        animate='animate'
        exit='exit'
      >
        <h2 className={`py-2`}>{article.title}</h2>
        <h6 className={`${styles.dates} fw-lighter`}>Date: {article.date}</h6>
        { 
          article.updated &&
          <h6 className={`${styles.dates} fw-lighter`}>Updated: {article.updated}</h6>
        }
        <div className={`d-flex justify-content-evenly`}>
          { renderCloseButton() }
          <Switch theme={theme} toggleTheme={toggleTheme} />
        </div>
        <hr className={`my-4`} />
        
        { article.html.map((e, index) => <span key={index} className={`${styles.span} fw-lighter`}>{e}</span>) }
        <div className={`w-100 text-end`}>
          <img src={profile} alt='profile' className={`${styles.profile} img-fluid w-25`}/>
        </div>
        { renderCloseButton() }
      </motion.div>
    </motion.div>
  )
};
