import { motion } from 'framer-motion';
import { FaGithub } from 'react-icons/fa';
import ButtonLine from '../../ButtonLine/ButtonLine';
import styles from './RowScroll.module.css';

const RowScroll = ({theme}) => {
  return (
    <div className={`${styles.row} row w-100 mx-auto`}>
      <h4 className={`${styles.h4} text w-100 pt-5 my-4 text-center`}>
        ScrollTainer UI Experiment
      </h4>
      <motion.div
        className={`${styles.container} col-md-6 my-auto p-4`}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.125, duration: 0.5 }}
      >
        <iframe 
          className={`${styles.iframe} ratio`} 
          src="https://youtube.com/embed/UeJb4kh-nwI" 
          frameBorder="0" 
          allowFullScreen 
          title='ScrollTainer | UI Experiment' 
        />
      </motion.div>
      <div className={`${styles.wrapper} mt-5 col-md-6 textContrast text-start my-auto`}>
        <p className={`${styles.p}`}>
          This was one of my contributions to the Forbes UI Showcase.  Read blog below article for more details.
        </p>
        <p className={`${styles.p}`}>
          This "mini-project" was developed during my Forbes Media internship.  It was built with React and Framer-motion. 
        </p>
        <div className={`${styles.buttonWrapper} gap-4 mx-auto mt-3 mb-5`}>
          <ButtonLine
            type={'a'}
            to={'https://codetracklift.github.io/scrolltainer/'}
            text={'DEMO'}
            theme={theme}
          />
          <ButtonLine
            type={'NavLink'}
            to={'/blog'}
            text={'BLOG'}
            theme={theme}
          />
          <ButtonLine
            type={'aHtml'}
            to={'https://github.com/codeTrackLift/scrolltainer#readme'}
            html={[<FaGithub className={`mb-1 mx-1`} />, 'README']}
            theme={theme}
          />
        </div>
      </div>
    </div>
  )
}

export default RowScroll;
