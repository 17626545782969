import { motion } from 'framer-motion';
import { BsGearWideConnected, BsCodeSlash, BsShieldCheck } from 'react-icons/bs';
import { FaRegNewspaper } from 'react-icons/fa';
import { MdMobileFriendly } from 'react-icons/md'; 
import { GiDiploma } from 'react-icons/gi';

import styles from './RowIcons.module.css';

const renderIntroBlock = (icon, h4, p, delay) => {
  return (
    <motion.div
      className={`${styles.col} col-sm-6 col-md-4 text-center my-5 hover py-5`}
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: delay, duration: 0.5 }}
    >
      {icon}
      <h4 className={`${styles.h4}`}>{h4}</h4>
      <p className={`${styles.p} textContrast`}>{p}</p>
    </motion.div>
  )
}

const RowIcons = () => {
  return (
    <div className={`${styles.row} row mx-auto`}>
      {renderIntroBlock(<GiDiploma size={50} className={`${styles.icon} ${styles.degree}`} />,
        `Bachelor's Degree`,
        `I have a bachelor's degree, and a professional certificate in full stack MERN development.`,
        0
      )}
      {renderIntroBlock(<MdMobileFriendly size={50} className={`${styles.icon} ${styles.mobile}`} />,
        `Responsive Design`,
        `I am a web developer who is extremely passionate about mobile first and responsive design.`,
        0.25
      )}
      {renderIntroBlock(<BsCodeSlash size={50} className={`${styles.icon} ${styles.code}`} />,
        `Production Level Code`,
        `I write legible code, unit test suites, error handling, documentation, and handle merge conflicts.`,
        0.5
      )}
       {renderIntroBlock(<BsGearWideConnected size={50} className={`${styles.icon} ${styles.gear}`} />,
        `Experienced Professional`,
        `I have worked with the Forbes Media software engineering team on their production code base.`,
        0
      )}
      {renderIntroBlock(<BsShieldCheck size={50} className={`${styles.icon} ${styles.shield}`} />,
        `Quality Standards`,
        `I'm an experienced quality engineer, with 20+ years of professional pharmaceutical experience.`,
        0.25
      )}
      <motion.div
        className={`${styles.col} col-sm-6 col-md-4 text-center my-5 hover py-5`}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <FaRegNewspaper size={50} className={`${styles.icon} ${styles.news}`} />
        <h4 className={`${styles.h4}`}>Published Author</h4>
        <p className={`${styles.p} textContrast`}>
          During my tenure with Forbes Media, I was privileged to<a
            href='https://www.forbes.com/sites/pete-chu/'
            target='_blank'
            className='cornflowerBlue'
            rel='noreferrer'
          > publish articles </a>
          on Forbes.com.
        </p>
      </motion.div>
    </div>
  )
}

export default RowIcons