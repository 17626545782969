import { motion } from 'framer-motion';
import { RiHeartPulseLine } from 'react-icons/ri';
import ButtonLine from '../../ButtonLine/ButtonLine';

import ImageOverlay from '../../ImageOverlay/ImageOverlay';
import udel from '../../../assets/images/udelCampus.jpg';
import styles from './RowUdel.module.css';

const RowUdel = ({theme}) => {
  return (
    <div className={`${styles.row} row mx-auto py-5`}>
      <motion.div
        className={`${styles.img} col-md-6 text-center my-auto`}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ImageOverlay
          image={udel}
          alt={'University of Delaware'}
          html={[
            <h4 className='fw-lighter'>Bachelor's Degree in Biological Sciences</h4>,
            <p className={`${styles.imageP}`}>
              My family legacy was in medicine, so I found myself in the pharmaceutical space early in my career.
            </p>,
          ]}
        />
      </motion.div>

      <div className={`${styles.wrapper} col-md-6`}>
        <RiHeartPulseLine className={`${styles.icon} mx-auto mt-4`} size={40} />
        <h5 className={`${styles.h5} w-100 text-center`}>Passionate and Disciplined</h5>
        <p className={`${styles.p} textContrast`}>
          Coding has been a personal passion. Click below to learn more about me, or read my blog articles.
        </p>
        <p className={`${styles.p} textContrast`}>
          Motivated, but also disciplined; for those times when motivation alone, isn't enough.
        </p>
        <div className={`${styles.buttonWrapper} d-flex m-auto mb-5`}>
          <ButtonLine
            type={'NavLink'}
            to={'/about'}
            text={'ABOUT'}
            theme={theme}
          />
          <ButtonLine
            type={'NavLink'}
            to={'/blog'}
            text={'BLOG'} 
            theme={theme}
          />
        </div>
      </div>
    </div>
  )
};

export default RowUdel;
