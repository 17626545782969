import { AiFillTwitterCircle } from 'react-icons/ai';
import { BsLinkedin } from 'react-icons/bs';
import styles from './ArticleFooter.module.css';

const linkedStyle = {
  borderRadius: '4px',
}

const twitterStyle = {
  borderRadius: '50%',
}

const ArticleFooter = ({linkedin, twitter}) => {
  return (
    <div className={`float-end px-2`} >
      <p>Happy coding!<br/>- Pete</p>
      <div className={`text-center`}>
        {
          linkedin && 
          <a href={linkedin} target='_blank' rel='noreferrer' className={`${styles.hoverEffect} px-2`}>
            <BsLinkedin size={30} color={'#0072b1'} style={linkedStyle} />
          </a>
        }
        {
          twitter &&
          <a href={twitter} target='_blank' rel='noreferrer' className={`${styles.hoverEffect} px-2`}>
            <AiFillTwitterCircle size={30} color={'#1DA1F2'} style={twitterStyle} />
          </a>
        }
      </div>
      {
        (linkedin || twitter ) && 
        <cite className={`opacity-75 text-center px-2`}>related post</cite>
      }
    </div>
  )
}

export default ArticleFooter;
