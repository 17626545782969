import { NavLink } from 'react-router-dom';
import mapSpans from '../../utils/mapSpans';
import styles from './ButtonFull.module.css';

const ButtonFull = ({type, to, text, onClick, theme, html, textWhite }) => {
  if (type === 'NavLink') {
    return (
      <NavLink to={to} className={`
        ${styles.button} text
        ${ theme === 'light' ? `${styles.light} text-black` : null }`}>
        {text}
      </NavLink>
    )
  }
  if (type === 'button') {
    return (
      <button className={`
        ${styles.button} text
        ${ theme === 'light' ? `${styles.light} text-black` : null }`} onClick={onClick}>
        {text}
      </button>
    )
  }
  if (type === 'a') {
    return (
      <a 
        className={`
          ${styles.button} text
          ${ theme === 'light' ? `${styles.light} text-black` : null }`}
        href={to}
        target='_blank'
        rel='noreferrer'
        onClick={onClick}
      >
        {text}
      </a>
    )
  }
  if (type === 'aHtml') {
    return (
      <a 
        className={`
          ${styles.button} text
          ${ theme === 'light' ? `${styles.light} text-black` : null }
          ${ textWhite ? `text-white` : null }
        `}
        href={to}
        target='_blank'
        rel='noreferrer'
        onClick={onClick}
      >
        { mapSpans(html) }
      </a>
    )
  }
}

export default ButtonFull
