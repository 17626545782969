import React from 'react';
import { motion } from 'framer-motion';

import RowLenova from '../../components/Rows/RowLenova/RowLenova';
import RowStanley from '../../components/Rows/RowStanley/RowStanley';
import RowScroll from '../../components/Rows/RowScroll/RowScroll';
import RowXch from '../../components/Rows/RowXch/RowXch';
import ButtonFull from '../../components/ButtonFull/ButtonFull';
import Quotes from '../../components/Quotes/Quotes';
import quoteArray2 from '../../content/quoteArray2';
import styles from './Projects.module.css';

const Projects = ({theme}) => {
  return (
    <motion.div
      className={`${styles.projects}`}
      initial={{ y: 1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 1 }}
    >
      <h2 className={`${styles.h2} fs-1 fw-light my-5 text text-center`}>Projects</h2>
      
      <RowXch theme={theme} />
      
      <div className={`${styles.contrast} bgContrast my-5`}>
        <RowScroll theme={theme} />
        <RowStanley theme={theme} />
      </div>
        <RowLenova theme={theme} />
      
      <div className={`text-center fs-6 py-4 mt-5 fw-lighter textGray`}>
        Click below to demo more projects in my archives.
        <motion.div
          className={`${styles.buttonWrapper} d-flex justify-content-center my-5`}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.125, duration: 0.5 }}
        >
          <ButtonFull
            type={`a`}
            theme={theme}
            to={`https://codetracklift.github.io/codebypete/pages/projects.html`}
            text={`MORE PROJECTS`}
          />
        </motion.div>
      </div>
      <Quotes theme={theme} array={quoteArray2} />
    </motion.div>
  )
}

export default Projects;
