import ArticleFooter from '../../components/Articles/ArticleFooter/ArticleFooter';
import ArticleImage from '../../components/Articles/ArticleImage/ArticleImage';
import join from '../../assets/articles/forbesJoin.jpg';
import f from '../../assets/articles/f.jpg';

const pStyles = {
  lineHeight: '2rem',
}

const linkedin = `https://www.linkedin.com/posts/codebypete_reactjs-nextjs-sass-activity-6946485607661527040-S-_Z/`;
const twitter = `https://twitter.com/codeTrackLift/status/1540724101475622913`;

const article1 =  {
  title: 'Forbes Internship: Interview and First Three Weeks',
  description: 'These are reflections of the interview process and the first three weeks of being a software engineering intern at Forbes Media.',
  image: f,
  alt: 'Forbes Media Letter "F" Logo',
  date: 'Jun 25, 2022',
  updated: 'Sep 4, 2022',
  html: [
    <p className={`fw-bold`} style={pStyles}>Greetings!</p>,
    <p style={pStyles}>This is my recap of the first 3 weeks as a 43 year old Software Engineering Intern...</p>,
    <p className={`fw-bold`} style={pStyles}>TLDR:</p>,
    <ol className={``}>
      <li>Learned how to use Next.js, Sass, Jira, and Confluence</li>
      <li>Created a ButtonWithImages React component and developed many unit-tests using Jest</li>
      <li>Participated in team stand-up meetings and investigations</li>
      <li>Developed, merged, and closed 3 Jira Tickets and PRs</li>
      <li>The Forbes Frontend Engineering team is awesome!</li>
    </ol>,
    <ArticleImage
      src={f}
      alt={`Forbes Media Letter "F" Logo`}
    />,
    <p className={`fw-bold`} style={pStyles}>I never thought I would be so excited to go to work!</p>,
    <p style={pStyles}>It has now been three weeks since I started my full-time paid internship at Forbes Media as part of their Frontend Engineering team. During the second trimester of bootcamp, the career coaches at MIT xPRO hooked me up with a referral for this Engineering Internship. I wasn't expecting to get called for the interview, but was pleasantly surprised when it happened.</p>,
    <p style={pStyles}>The interview was conducted over Zoom and the process included a large group introduction, followed by a series of three 1:1 break-out sessions. For me, each session was with a member of the Frontend Engineering team. Everybody I spoke to was extraordinarily nice, but they did challenge me with technical JavaScript questions. In hindsight, there were several areas where I could have articulated a better answer. However, I was very fortunate, as the Frontend Engineering team decided to give me a chance!</p>,
    <ArticleImage
      src={join}
      alt={`A screenshot of Pete joining the Forbes Media organization on GitHub.`}
      wide
    />,
    <p style={pStyles}>It all became very real when I got my laptop (16" MacBook Pro) and monitor delivered and set up. Getting local admin on the laptop was the first challenge, this took almost 2 working days, but was well worth the effort! After getting privileges to execute 'sudo' commands, I started feeling like a developer again! This feeling did not last long as I immediately slipped back into imposter syndrome when I couldn't get my first 'npm install' to run.</p>,
    <p style={pStyles}>My mentor came to the rescue and helped remedy the issue (missing settings). From that point on, I was able to use git, clone the appropriate repos, install the necessary packages, and get my local development environment up and running! 😊</p>,
    <p style={pStyles}>The next major milestone was attending and participating in team stand-ups and meetings, and where I learned to use Jira and Confluence. It is quite the privilege to be able to listen in on these discussions, as they helped fill in missing tech vocabulary. This also gave me exposure to professional software engineers, their mindset, and how they rationalize real-world issues.</p>,
    <p style={pStyles}>During one of the meetings, it was mentioned that a new component was needed, specifically a button with a nested image. I got excited, as I had done something similar for my GitHub Readme buttons on my portfolio. However, I did not have the confidence to bring it up in the meeting. After wrapping up, I quietly messaged my mentor and showed him a screenshot of my GitHub Readme button. My mentor and the Frontend Engineering manager discussed, and decided to assign me my first Jira ticket!</p>,
    <p style={pStyles}>Everything I have developed thus far has been coded in a silo. So working on a large and complex codebase with a group was quite the eye-opener! Since the internship overlaps with the final trimester of bootcamp, Next.js was completely foreign to me as well as Sass. These were two technologies I had to pick up right away in order to contribute. Thankfully, LinkedIn has a course for Server-Side Rendering which covers Next.js and Sass was not too different from regular CSS.</p>,
    <p style={pStyles}>After making the button and verifying that it looks and functions as intended in my local development environment, I had to push it to the GitHub repository and make a pull request. Imposter syndrome was kicking in hard at this point, as I was about to expose my coding insecurities to the whole world (or so it felt)! I reached out to a junior developer on the team and shared my files with him, hoping for some reassurance. He gave me the thumbs up, then encouraged me to push the code, and so I did.</p>,
    <p style={pStyles}>Folks had warned me that your first PR as a developer could result in a lot of comments. 55 comments later, my ButtonWithImages could now be dynamically rendered with different SVGs, text, onClick functionality, and even included a suite of Jest unit tests! Each comment pointed out weaknesses in my code, like redundant and inefficient code, as well as failure to have good test coverage.</p>,
    <p style={pStyles}>This set a good back and forth tempo with the experienced software engineers on the team. I could share my attempts at a solution, then see how a master would do it. By the end of the third week, I had the opportunity to work on 6 React tickets and 3 of them were approved, merged, and closed. One of the tickets was to investigate an unintended issue; we have a follow-up meeting next week to discuss the investigation findings and determine a path forward.</p>,
    <p style={pStyles}>My first three weeks with Forbes validates my decision to leave my cushy pharmaceutical job to pursue my passion. I enjoy every minute of the work, learning each new technology feels exciting and rewarding, and the team is warm and welcoming. Maybe this will change if I ever lose the 'intern' status; however, I will not worry about it right now as I only have a limited time with this team.</p>,
    <p style={pStyles}>I hope to make the most out of every minute...</p>,
    <ArticleFooter
      linkedin={linkedin}
      twitter={twitter} 
    />
  ],
};

export default article1;
