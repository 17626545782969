import { motion } from 'framer-motion';
import { FaPeopleCarry } from 'react-icons/fa';
import ButtonLine from '../../ButtonLine/ButtonLine';
import ImageOverlay from '../../ImageOverlay/ImageOverlay';
import mit from '../../../assets/images/mit.jpg';
import resume from '../../../assets/resumeByPete.pdf'
import styles from './RowMit.module.css';

const RowMit = ({theme}) => {
  return (
    <div className={`${styles.row} row m-auto pt-5`}>
       <div className={`${styles.wrapper} col-md-6 pt-5`}>
        <FaPeopleCarry className={`${styles.icon} mx-auto`} size={40} />
        <h5 className={`${styles.h5} w-100 text-center`}>Collaboration Skills</h5>
        <p className={`${styles.p} textContrast`}>
          Agile development methodology, kanban, scrum, release branching, CI/CD pipline.
        </p>
        <p className={`${styles.p} textContrast`}>
          Over two decades of translatable skills from pharmaceutical engineering.
        </p>
        <div className={`${styles.buttonWrapper} m-auto mb-5 pb-5`}>
          <ButtonLine
            type={'NavLink'}
            to={'/projects'}
            text={'PROJECTS'}
            theme={theme}
          />
          <ButtonLine
            type={'a'}
            text={'RESUME'}
            to={resume} 
            theme={theme}
          />
        </div>
      </div>
      <motion.div
        className={`${styles.img} col-md-6 text-center my-auto`}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ImageOverlay
          image={mit}
          alt={'Massachusetts Institute of Technology'}
          html={[
            <h4 className='fw-lighter'>Professional Certificate, <span className='fst-italic'>Full Stack MERN Development</span></h4>,
            <p className={`${styles.imageP}`}>
              I graduated coding bootcamp with a 4.0 GPA.  Please demo my projects, or review my resume.
            </p>,
          ]}
        />
      </motion.div>
    </div>
  )
};

export default RowMit;
