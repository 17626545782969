import { motion } from 'framer-motion';
import ButtonLine from '../../ButtonLine/ButtonLine';
import stanley from '../../../assets/images/stanley.gif';
import styles from './RowStanley.module.css';

const RowStanley = ({theme}) => {
  return (
    <div className={`${styles.row} row mx-auto pb-5`}>
      <h4 className={`${styles.h4} text mb-5 pt-5 text-center`}>Stanley Cooling Website</h4>
      <div className={`${styles.wrapper} col-sm-6 col-md-7`}>
        <p className={`${styles.p} textContrast`}>
          Stanley Cooling is a hand-crafted React App developed with reusable components and custom assets.
        </p>
        <p className={`${styles.p} textContrast`}>
          Subtle animations were added for a smooth and comfortable user experience.
        </p>
        <p className={`${styles.p} textContrast`}>
          Tuned for web performance, accessibility, search engine optimization, and launching soon!
        </p>
        <div className={`${styles.buttonWrapper} m-auto pb-5 mt-4 mb-4`}>
           <ButtonLine
            type={'a'}
            to={'https://codetracklift.github.io/stanley'}
            text={'VISIT SITE'}
            theme={theme}
          />
        </div>
      </div>
      <motion.div
        className={`${styles.col} col-sm-6 col-md-5 my-auto p-4 text-center`}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.125, duration: 0.5 }}
      >
        <img
          src={stanley}
          alt='Welcome to the Stanley Cooling Corp, HVAC Experts with reliable service, affordable financing, and proudly serving the Southwest Florida area with over a a decade of professional experience.'
          className={`${styles.img} img-fluid dropShadowBig`}
        />
      </motion.div>
    </div>
  )
}

export default RowStanley;
