const formSubmit = (message) => {
   // API call
   fetch("https://formsubmit.co/ajax/f61adf2ff38bc5c4deb30cb261bf1ec0", {
    method: "POST",
    headers: { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
      body: JSON.stringify({
        message: message,
      })
    })
    .then(response => response.json())
    // .then(data => console.log(data.message))
    // .catch(error => console.log(error.message));
}

export default formSubmit;
