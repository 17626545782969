import React from 'react';
import { motion } from 'framer-motion';
import Contact from '../Contact/Contact';
import logo from '../../assets/images/pharma2code.gif'
import styles from './Footer.module.css';

export default function Footer({theme}) {
  const currentYear = new Date().getFullYear();

  return (
    <motion.div
      className={`${styles.div} boxShadowDeep`}
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Contact theme={theme} />
      
      <footer className={`${styles.footer}`}>
        <p className={`textSilver`}>Copyright © {currentYear}
          <img src={logo}
            style={{ height: '2rem', padding: '0 0.25rem' }}
            alt="codeByPete logo" />
          <span className='fst-italic textShadow'>
            <span style={{ color: "silver" }}> code</span>
            <span style={{ color: "#00857c" }}>By</span>
            <span style={{ color: "lime" }}>Pete</span>
          </span>
        </p>
      </footer>
    </motion.div>
  )
};
