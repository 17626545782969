import ArticleFooter from '../../components/Articles/ArticleFooter/ArticleFooter';
import ArticleImage from '../../components/Articles/ArticleImage/ArticleImage';
import puzzlePiece from '../../assets/articles/puzzlePiece.gif';
import laptop from '../../assets/articles/pairProgrammingLaptop.jpg';

const pStyles = {
  lineHeight: '2rem',
}

const aStyles = {
  fontColor: 'cornflowerblue',
}

const linkedin = `https://www.linkedin.com/posts/anastasiia-soktoeva-313199144_pair-programming-activity-7006668311740493824-NotS`;
const twitter = ``;
const urlAna = `https://www.linkedin.com/in/anastasiia-soktoeva-313199144/`;
const urlArticle = `https://www.forbes.com/sites/forbesdigitalgroup/2022/12/08/pair-programming/`;

const article6 =  {
  title: 'How Can Pair Programming Drive Collaboration and Efficiency',
  description: `Pair programming can be a fun and efficient way to write production level code.  This article was co-authored by Anastasiia Soktoeva and myself, and is published on Forbes.com.`,
  image: puzzlePiece,
  alt: 'Two hand putting togther puzzle pieces.',
  date: 'Dec 8, 2022',
  updated: '',
  html: [
    <p className={`fw-bold`} style={pStyles}>Greetings!</p>,
    <p style={pStyles}>The article below is published on Forbes.com and was co-authored by <a href={urlAna} target='_blank' rel='noreferrer'>Anastasiia Soktoeva</a> and myself. This article summarizes an Emeritus interview about my coding journey. Please see this <a href={urlArticle} target='_blank' rel='noreferrer' style={aStyles}>link</a> for the original article on Forbes.com.</p>,
    <hr />,
    <ArticleImage
      src={puzzlePiece}
      alt={`Two hand putting togther puzzle pieces.`}
      wide
    />,
    <hr/>,
    <p className={`fw-bold`} style={pStyles}>[Anastasiia’s Flashback]</p>,
    <p style={pStyles}>Have you ever wondered what Pair Programming is, or how it works? “If I were to summarize Pair Programming in four words, it would be <span className={`fst-italic`}>‘Two programmers, one pencil.’</span>”</p>,
    <p style={pStyles}>One day I was just listening to a tech podcast about software development techniques and heard about Pair Programming. When I joined Forbes, I introduced this practice to my team. I think it's a common misconception that software development is a solitary field. Rather, teamwork or collaboration is a key element in software development projects.</p>,
    <p style={pStyles}>I went out on maternity leave.</p>,
    <p style={pStyles}>It was a fantastic period as a mother. But, my developer hormones were continuously pushing me to start my job as soon as possible and catch up faster with a team.</p>,
    <p style={pStyles}>Here comes the point where the pair programming felt like bliss, so I messaged Pete as he had some knowledge with unit tests at that time, to walk me through the project.</p>,

    <p className={`fw-bold`} style={pStyles}>[Pete’s Flashback]</p>,
    <p style={pStyles}>Seven weeks into my summer internship at Forbes Media, Anastasiia sent me a Slack message about working on her first unit-test ticket. I had merged about a handful of unit-test pull requests at this point, but that development (and struggle!) was done in isolation. No one had to watch as I stared blankly at error messages for (what seemed like) hours on end.</p>,
    <p style={pStyles}>Fortunately, I had a little bit of time to prepare. Something my mentor previously said struck me at that moment. “Look for patterns in the code...”</p>,
    <p style={pStyles}>Before we met up to work on the unit-test, I looked over the component in question and tried to assess its complexity and functionality. Then found similar components that had already had unit-tests written. Armed with a couple of unit-test examples, we connected and I started by screen sharing my code editor.</p>,
    <p className={`fw-bold`} style={pStyles}>[Anastasiia’s perspective, Google Meet]</p>,
    <p style={pStyles}>Pete and I connected, we reviewed some unit-test examples, and discussed the imports. Next, we reviewed rendering a component, then challenging it with an assertion. Afterwards, we ran the tests to have a visual example of passing results.</p>,
    <p style={pStyles}>Next step was to modify the unit-test so that it fails. Failing the test would render the component code to the console and make it a little bit easier to see what the unit-test is actually rendering. After this, we decided to start the development for the unit-test ticket.</p>,
<p className={`fw-bold`} style={pStyles}>[Pete’s perspective]</p>,
    <p style={pStyles}>Since I was ‘driving’ I reverted to my natural development habits… A few minutes into this, I realize that I’m talking to myself as I code (this helps me keep some of the abstract logic straight). It’s part of what I naturally tend to do, but now someone is listening to me!</p>,
    <p style={pStyles}>Turns out this was helpful as it was a bit easier to follow my thought process since I was being more vocal.</p>,
    <p style={pStyles}>Finally, we touch on code coverage. We discuss accessing the report, as well as interpreting the results. At that point, Anastasiia (with an excited tone in her voice) had enough!</p>,
    <p style={pStyles}>She sounded comfortable and wanted to try it from scratch, on her own, or maybe she was just tired of listening to me talk to myself.</p>,
    <ArticleImage
      src={laptop}
      alt={`Laptop showing the Forbes logo.`}
    />,
    <p className={`fw-bold`} style={pStyles}>[Anastasiia and Pete] Perks of Pair Programming:</p>,
    <p style={pStyles}><span className={`fw-bold`}>Fewer Bugs and Quality Code</span> - Two developers quickly correct each other's mistakes and use shared knowledge to solve problems faster. Two sets of eyes can see more problems than one; both have different ways to identify the problem.</p>,
    <p style={pStyles}><span className={`fw-bold`}>Faster Training</span> - In pair programming, the collaborators are usually both experienced or one expert and one novice. It is a huge perk for the juniors to learn quicker and for the company to speed up the onboarding process.</p>,
    <p style={pStyles}><span className={`fw-bold`}>Increases teamwork and team communication</span> - Last but not least, one of the amazing benefits of practicing pair programming is increased team communication and building opportunities. While doing the pairing, developers inform each other more about their work and trust each other. It helps build better relationships with other colleagues.</p>,
    <p style={pStyles}>Even in remote pairing, teams work together and interact in different time zones and locations. And we believe that building and strengthening work relationships are essential for any successful agile team.</p>,
    <p style={pStyles}><span className={`fw-bold`}>Increases teamwork and team communication</span> - Last but not least, one of the amazing benefits of practicing pair programming is increased team communication and building opportunities. While doing the pairing, developers inform each other more about their work and trust each other. It helps build better relationships with other colleagues.</p>,
    <p style={pStyles}><span className={`fw-bold`}>Wrapping up</span> - If you've ever heard that 'two minds are better than one', it is pretty true in the context of software engineering.</p>,
    <ArticleFooter
      linkedin={linkedin}
      twitter={twitter} 
    />,
  ],
};

export default article6;
