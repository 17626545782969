import React from 'react'
import { motion } from 'framer-motion';
import { BsLinkedin, BsYoutube } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { AiFillTwitterCircle } from 'react-icons/ai';
import styles from './Top.module.css';

const renderSocials = (Icon, className, url, delay) => {
  return (
    <motion.a
      href={url}
      target='_blank'
      whileHover={{
        y: '-15%',
        transition: {
          duration: 0.125,
          type: 'spring',
          stiffness: 250,
          weight: 500,
        },
      }}
      whileTap={{ y: '-15%' }}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0, 
        transition: {
          delay: (delay),
          duration: 0.25,
          type: 'spring',
          stiffness: 100,
          weight: 1000
        }
      }}
    >
      <Icon className={className} size={30} />
    </motion.a>
  )
} 

const Top = () => {
  return (
    <div id='top' className={`${styles.topWrapper}`}>
      <motion.h3
        id='navbarCBP' className={`${styles.cbp}`}
        initial={{ opacity: 0, x: '-15%'}}
        whileInView={{ opacity: 1, x: '15%' }}
        transition={{ delay: 0.125, duration: 0.5, type: 'spring' }}
      >
        <span className={`${styles.cbpCode} textShadow`}>
          <div>c</div>
          <div>o</div>
          <div>d</div>
          <div>e</div>
        </span>
        <span className={`${styles.cbpBy} textShadow`}>
          <div>B</div>
          <div>y</div>
        </span>
        <span className={`${styles.cbpPete} textShadow`}>
          <div>P</div>
          <div>e</div>
          <div>t</div>
          <div>e</div>
        </span>
      </motion.h3>
      
      <div className={`${styles.socials}`}>
        {renderSocials(BsLinkedin, `${styles.linkedin} dropShadow`,
          `https://www.linkedin.com/in/codebypete/`, 0.125
        )}
        {renderSocials(FaGithub, `${styles.github} dropShadow`,
          `https://github.com/codeTrackLift`, 0.25
        )}
        {renderSocials(BsYoutube, `${styles.youtube} dropShadow`, 
          `https://www.youtube.com/@codebypete`, 0.375
        )}
        {renderSocials(AiFillTwitterCircle, `${styles.twitter} dropShadow`, 
          `https://twitter.com/codeTrackLift`, 0.5
        )}
      </div>
    </div>
  )
}

export default Top;
