import { motion } from 'framer-motion';
import { AiOutlineCloudServer, AiOutlineHtml5 } from 'react-icons/ai';
import { BsKanban } from 'react-icons/bs';
import { CgPerformance } from 'react-icons/cg';
import { DiGoogleAnalytics, DiJira, DiScrum } from 'react-icons/di';
import { FaBootstrap, FaGitAlt, FaNodeJs, FaReact } from 'react-icons/fa';
import { IoLogoCss3, IoLogoJavascript, IoLogoSass } from 'react-icons/io';
import { SiDocker, SiExpress, SiGraphql, SiJest, SiMongodb, SiTypescript } from 'react-icons/si';
import { TbBrandNextjs } from 'react-icons/tb';
import styles from './RowTech.module.css';

const iconArray = [
  {
    icon: <IoLogoJavascript className={`${styles.javascript}`} size={30}/>,
    text: `JavaScript`,
  },
  {
    icon: <FaReact className={`${styles.react}`} size={30}/>,
    text: `ReactJS`,
  },
  {
    icon: <FaGitAlt className={`${styles.git}`} size={30}/>,
    text: `Git`,
  },
  {
    icon: <SiJest className={`${styles.jest}`} size={30}/>,
    text: `Unit Testing`,
  },
  {
    icon: <AiOutlineCloudServer className={`${styles.rest}`} size={30}/>,
    text: `Rest API`,
  },
  {
    icon: <DiGoogleAnalytics className={`${styles.seo}`} size={30}/>,
    text: `SEO`,
  },
  {
    icon: <DiJira className={`${styles.jira}`} size={30}/>,
    text: `Jira`,
  },
  {
    icon: <CgPerformance className={`${styles.performance}`} size={30}/>,
    text: `Web Performance`,
  },
  {
    icon: <BsKanban className={`${styles.kanban}`} size={30}/>,
    text: `Kanban`,
  },
  {
    icon: <SiDocker className={`${styles.docker}`} size={30}/>,
    text: `Docker`,
  },
  {
    icon: <SiGraphql className={`${styles.graphql}`} size={30}/>,
    text: `GraphQL`,
  },
  {
    icon: <FaBootstrap className={`${styles.bootstrap}`} size={30}/>,
    text: `Bootstrap`,
  },
  {
    icon: <AiOutlineHtml5 className={`${styles.html}`} size={30}/>,
    text: `HTML`,
  },
  {
    icon: <IoLogoCss3 className={`${styles.css}`} size={30}/>,
    text: `CSS`,
  },
  {
    icon: <FaNodeJs className={`${styles.node}`} size={30}/>,
    text: `NodeJS`,
  },
  {
    icon: <SiExpress className={`${styles.express}`} size={30}/>,
    text: `ExpressJS`,
  },
  {
    icon: <SiMongodb className={`${styles.mongo}`} size={30}/>,
    text: `MongoDB`,
  },
  {
    icon: <SiTypescript className={`${styles.typescript}`} size={30}/>,
    text: `TypeScript`,
  },
  {
    icon: <TbBrandNextjs className={`${styles.next}`} size={30}/>,
    text: `NextJS`,
  },
  {
    icon: <IoLogoSass className={`${styles.sass}`} size={30}/>,
    text: `Sass`,
  },
  {
    icon: <DiScrum className={`${styles.scrum}`} size={30}/>,
    text: `Scrum`,
  },
]

const addMotionIcon = (html) => {
  return (
    <>
      {
        html.map((e, index) => {
          return <motion.span
            className={`${styles.span}`}
            key={index}
            whileHover={{
              scale: 1.25,
              transition: {
                duration: 0.25,
                type: 'spring',
                stiffness: 500,
              },
            }}
            whileTap={{ scale: 0.85 }}
          >
            {
              <>
              {e.icon}
              <p className={`fw-lighter`}>{e.text}</p>
              </>
            }
          </motion.span>
        })
      }
    </>
  )
}

const RowTech = () => {
  return (
    <div 
      id='scrollTainer'
      className={`${styles.scroll} textContrast`}
    >
      { addMotionIcon(iconArray)}
    </div>
  )
};

export default RowTech;
