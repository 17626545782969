const injectHeaderH1 = () => {
  return (
    <>
    <div>
      <div>W</div>
      <div>e</div>
      <div>b</div>
    </div>
    <div>
      <div>D</div>
      <div>e</div>
      <div>v</div>
      <div>e</div>
      <div>l</div>
      <div>o</div>
      <div>p</div>
      <div>e</div>
      <div>r</div>
    </div>
    </>
  )
};

const injectHeaderH3 = () => {
  return (
    <>
    <div>
      <div>r</div>
      <div>e</div>
      <div>s</div>
      <div>p</div>
      <div>o</div>
      <div>n</div>
      <div>s</div>
      <div>i</div>
      <div>v</div>
      <div>e</div>
    </div>
    <div>
      <div>d</div>
      <div>e</div>
      <div>s</div>
      <div>i</div>
      <div>g</div>
      <div>n</div>
    </div>
    </>
  )
};

const injectHeaderH4 = () => {
  return (
    <>
    <span className={`px-2`}>
      <div>d</div>
      <div>e</div>
      <div>d</div>
      <div>i</div>
      <div>c</div>
      <div>a</div>
      <div>t</div>
      <div>e</div>
      <div>d</div>
    </span>
    <span className={`px-3`}>
      <div>a</div>
      <div>r</div>
      <div>t</div>
      <div>i</div>
      <div>c</div>
      <div>u</div>
      <div>l</div>
      <div>a</div>
      <div>t</div>
      <div>e</div>
    </span>
    <span className={`px-3`}>
      <div>p</div>
      <div>r</div>
      <div>o</div>
      <div>f</div>
      <div>e</div>
      <div>s</div>
      <div>s</div>
      <div>i</div>
      <div>o</div>
      <div>n</div>
      <div>a</div>
      <div>l</div>
    </span>
    </>
  )
};

export { injectHeaderH1, injectHeaderH3, injectHeaderH4 };