import React from 'react'
import { motion } from 'framer-motion';
import { AiOutlineMail, AiFillTwitterCircle } from 'react-icons/ai';
import { BsLinkedin, BsYoutube } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { MdOutlineLocationOn } from 'react-icons/md';

import addMotion from '../../utils/addMotion'
import shuffle from '../../utils/shuffle'
import styles from './Info.module.css'
import { toast } from 'react-toastify';

const renderLi = (item, url, className) => {
  return (
    <li className={`${styles.li}`}>
      <motion.a
        className={className}
        href={url}
        target='_blank'
        rel='noreferrer'
        whileHover={{
          scale: 1.1,
          transition: {
            duration: 0.125,
          },
        }}
        whileTap={{ y: '-15%' }}
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0,
          transition: {
            duration: 0.5,
          }
        }}
      >
        <span className={`${styles.span} d-flex gap-3 fw-lighter`}>{item}</span>
      </motion.a>
    </li>
  )
}

const renderUlTop = () => {
  return (
    <ul className={`${styles.ul}`}>
      {renderLi(<>{addMotion([<BsLinkedin className={`${styles.linkedin}`} size={30} />])}@codeByPete</>, `https://www.linkedin.com/in/codebypete/`)}
      {renderLi(<>{addMotion([<FaGithub className={`${styles.github}`} size={30} />])}@codeTrackLift</>, `https://github.com/codeTrackLift`)}
      {renderLi(<>{addMotion([<AiFillTwitterCircle className={`${styles.twitter}`} size={30} />])} @codeTrackLift</>, `https://twitter.com/codeTrackLift`)}
    </ul>
  )
}

const copyEmail = (e) => {
  e.preventDefault();
  navigator.clipboard.writeText(shuffle('pbqrGenpxYvsg@tznvy.pbz'))
  toast.info('Email copied to clipboard.')
}

const renderUlBottom = () => {
  return (
    <ul className={`${styles.ul}`}>
      {renderLi(<>{addMotion([<BsYoutube className={`${styles.youtube}`} size={30} />])}@codeByPete</>, `https://www.youtube.com/@codebypete`)}
      {
        <li className={`${styles.li}`}>
          <motion.div
            className={`${styles.locationWrapper} mt-1 fw-lighter`}
            whileHover={{
              scale: 1.1,
              transition: {
                duration: 0.125,
              },
            }}
            whileTap={{ y: '-15%' }}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0,
              transition: {
                duration: 0.5,
              }
            }}
          >
            <MdOutlineLocationOn className={`${styles.location}`} size={30} />
            United States
          </motion.div>
        </li>
      }
      {
         <li className={`${styles.li}`}>
          <motion.div
            className={`${styles.emailButton} fw-lighter`}
            onClick={(e) => copyEmail(e)}
            whileHover={{
              scale: 1.1,
              transition: {
                duration: 0.125,
              },
            }}
            whileTap={{ y: '-15%' }}
            initial={{ opacity: 0, x: -100}}
            whileInView={{ opacity: 1, x: 0,
              transition: {
                duration: 0.5,
              }
            }}
          >
            <AiOutlineMail className={`${styles.emailIcon}`} size={30} />
            Click to copy email
          </motion.div>
        </li>
      }
    </ul>
  )
}

export default function Info() {
  return (
    <div className={`row my-5`}>
      <div className={`col-lg-6 d-none d-sm-none d-md-block`}>
        {renderUlTop()}
      </div>
      <div className={`col-lg-6 d-none d-sm-none d-md-block`}>
        {renderUlBottom()}
      </div>
      <div className={`col-sm-5 d-md-block d-md-none`}>
        <ul className={`${styles.ul}`}>
          {renderUlTop()}
        </ul>
      </div>
      <div className={`col-sm-7 d-md-block d-md-none`}>
        <ul className={`${styles.ul}`}>
          {renderUlBottom()}
        </ul>
      </div>
    </div>
  )
};
