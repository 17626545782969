import { createContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify'

import About from '../pages/About/About';
import Blog from '../pages/Blog/Blog';
import Home from '../pages/Home/Home';
import Projects from '../pages/Projects/Projects';

import '../styles/colorsBg.css';
import '../styles/colorsText.css';
import '../styles/global.css';
import '../styles/typography.css';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import Modal from './Modal/Modal';
import NavBar from './NavBar/NavBar';
import Spacer from './Spacer/Spacer';
import Top from './Top/Top';
import Thank from './Thank';

import formSubmit from '../utils/formSubmit';

export const ThemeContext = createContext(null);

function App() {
  /**
   * Light mode / dark mode theme
   */
  const themeByPete = localStorage.getItem('themeByPete');
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem('themeByPete')) || 'light');
  const toggleTheme = () => { setTheme((e) => (e === 'light' ? 'dark' : 'light')) };
  useEffect(() => {
    const body = document.querySelector('body')
    if (theme === 'dark') {
      body.style.backgroundColor = 'rgb(50,50,50)';
      localStorage.setItem('themeByPete', JSON.stringify('dark'));
      return;
    } else {
      body.style.backgroundColor = 'ghostwhite';
      localStorage.setItem('themeByPete', JSON.stringify('light'));
    }
    if(themeByPete === null) formSubmit('New visitor notification')
  }, [theme, themeByPete]);

  /**
   * Pathing and location
   */
  const [path, setPath] = useState('/')
  let location = useLocation();
  useEffect(() => {
    setPath(location.pathname)
    if (location.pathname === '/') {
      setTimeout(() => document.querySelector(`#top`).scrollIntoView({ block: "center", behavior: "smooth" }), 125);
      return;
    }
    setTimeout(() => document.querySelector(`#spacer`).scrollIntoView({ block: "start", behavior: "smooth" }), 125);
  }, [location, path]);

  /**
   * Blog article modal
   */
  const [modalOpen, setModalOpen] = useState(false);
  const [article, setArticle] = useState('');
  const close = () => {
    setModalOpen(false); 
    setTimeout(() => document.querySelector(`#spacer`).scrollIntoView({ block: "start", behavior: "smooth" }), 250)
  };
  const open = (blogArticle) => {
    setArticle(blogArticle);
    setModalOpen(true)
    setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}), 500);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <AnimatePresence initial={true} exitBeforeEnter={false}>
        { 
          !modalOpen &&
          <motion.div className='bg' id={theme}>
            <Header />
            <NavBar theme={theme} toggleTheme={toggleTheme} />
            <Spacer />

            <Routes>
              <Route path='/*' element={<Home theme={theme} />}/>
              <Route path='/about' element={<About theme={theme} />} />
              <Route path='/blog'
                element={
                  <Blog
                    location={location}
                    theme={theme}
                    modalOpen={modalOpen}
                    open={open}
                    close={close}
                  />
                }
              />
              <Route path='/projects' element={<Projects theme={theme} />} />
            </Routes>
            <Thank />
          </motion.div>
        }
      </AnimatePresence>

      <AnimatePresence initial={true} exitBeforeEnter={true}>
        { 
          modalOpen && 
          <>
          <Top />
          <Modal handleClose={close} article={article} theme={theme} toggleTheme={toggleTheme} />
          </>
        }
      </AnimatePresence>

      <ToastContainer />
      <Footer theme={theme} />
    </ThemeContext.Provider>
  );
}

export default App;
