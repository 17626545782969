import Carousel from 'react-bootstrap/Carousel';
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs';
import useWindowDimensions from '../../utils/windowDimensions';
import recommendArray from '../../content/recommendArray';
import addMotion from '../../utils/addMotion';
import styles from './Recommends.module.css';

const carouselStyle = {
  zIndex: 1,
  margin: '2rem 0 1rem 0',
  backgroundColor: 'transparent'
}

const injectCite = (recommend) => {
  return (
    <cite title="Source Title" className="">
      {recommend.author}<br/>
      {recommend.company}
    </cite>
  )
}

const injectCarouselItems = (array) => {
  const recommends = array.map((recommend, i) => {
    return (
      <Carousel.Item
        className={`${styles.item} pt-4`}
        key={i}
        interval={30000}
      >
        <h5 className={`${styles.title} text`}>{recommend.title}</h5>
        <p className={`${styles.text} opacity-75`}>
          {recommend.text}
        </p>
        <figcaption className={`${styles.figcaption} text text-end mt-3`}>
          {injectCite(recommend)}
        </figcaption>
      </Carousel.Item>
    )
  })
  return recommends;
}

export default function Recommends({theme}) {
  const { width } = useWindowDimensions();

  return (
    <Carousel
      className={`${styles.carousel} carousel slide my-5`} 
      data-bs-ride="carousel" 
      indicators={true} 
      style={carouselStyle}
      nextIcon={ width > 575 &&
        addMotion([<BsArrowRightCircle color={'white'} className='dropShadow' size={30} />])
      }
      prevIcon={ width > 575 &&
        addMotion([<BsArrowLeftCircle color={'white'} className='dropShadow' size={30} />])
      }
      touch
      fade
    >
      {injectCarouselItems(recommendArray)}
    </Carousel>
  );
};
