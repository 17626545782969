import mapSpans from '../../utils/mapSpans';
import styles from './ImageOverlay.module.css';

const ImageOverlay = ({image, alt, html, style}) => {

  return (
    <figure
      className={`${styles.hoverImg}`}
      style={style}
    >
      <img src={image} alt={alt} />
      <figcaption className={`${styles.figcaption}`}>
        { mapSpans(html) }
      </figcaption>
    </figure>
  )
}

export default ImageOverlay;
