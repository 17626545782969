import gtMetrix from '../assets/gtMetrix.pdf'

export default function Thank() {
  return (
    <div style={{paddingBottom: '6rem',}}>
      <h4
        className='text-center mt-5 pb-4 pt-5 text opacity-50'
        style={{fontSize: '2rem', fontWeight: 100}}
      >
        Thank you for visiting my portfolio!
      </h4>
      <h6
        className='text-center textGray mb-5 pb-5'
        style={{fontSize: '1rem', fontWeight: 100}}
      >
        This hand-crafted react app was tuned using GTmetrix, see
        <a
          href={gtMetrix}
          target='_blank'
          rel='noreferrer'
          className='cornflowerBlue'
        > performance report</a>.
      </h6>
    </div>
  )
};
