import { motion } from 'framer-motion';
import ButtonLine from '../../ButtonLine/ButtonLine';
import lenova from '../../../assets/images/lenova.gif';
import styles from './RowLenova.module.css';

const RowLenova = ({theme}) => {
  return (
    <div className={`${styles.row} row mx-auto pb-5`}>
      <h4 className={`${styles.h4} text mb-5 pt-5 text-center`}>Engineering Firm Website</h4>
      <motion.div
        className={`${styles.col} col-md-6 my-auto p-4`}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.125, duration: 0.5 }}
      >
        <img
          src={lenova}
          alt='Lenova, Inc. Engineering, providing professional engineering solutions for over 30 years.  Specialzing in the biopharmaceutical, commercial, and healthcare industries.  Offices in PA, NJ, and FL.'
          className={`img-fluid dropShadowBig`}
        />
      </motion.div>
      <div className={`${styles.wrapper} col-md-6 mt-5`}>
        <h5 className={`${styles.h5} text mx-auto`}>Ranked #1 on Google!</h5>
        <p className={`${styles.p} textContrast`}>
          LeNova, Inc.'s business website was successfully launched ahead of schedule and under budget.
        </p>
        <p className={`${styles.p} textContrast`}>
          Carefully crafted with direction from the product owner.
        </p>
        <p className={`${styles.p} textContrast`}>
          Tuned for web performance and search engine optimization.
        </p>
        <div className={`${styles.buttonWrapper} m-auto pb-5 mt-4 mb-4`}>
           <ButtonLine
            type={'a'}
            to={'https://www.lenovainc.com/'}
            text={'VISIT SITE'}
            theme={theme}
          />
          <ButtonLine
            type={'a'}
            to={'https://www.google.com/search?q=lenova+inc+engineering'}
            text={'DEMO SEO'}
            theme={theme}
          />
        </div>
      </div>
    </div>
  )
}

export default RowLenova;
