import { motion } from 'framer-motion';

export default function addMotion(html, scale, stiffness) {
  return (
    <motion.div
      whileHover={{
        scale: (scale || 1.125),
        transition: {
          delay: 0,
          duration: 0.25,
          type: 'spring',
          stiffness: (stiffness || 250),
        },
      }}
      whileTap={{ scale: 0.85, 
        transition: {
          delay: 0,
          duration: 0.25,
          type: 'spring',
          stiffness: (stiffness || 250),
        },
      }}
      style={{transition:'color 0.25s ease-in-out'}}
    >
      {html.map((e, index) => {
          return <span key={index}>{e}</span>
        })
      }
    </motion.div>
  )
};
