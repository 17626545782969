import React from 'react'
import styles from './Spacer.module.css';

const Spacer = () => {
  return (
    <div id='spacer' className={styles.spacer}></div>
  )
};

export default Spacer;
