import { useEffect } from 'react';
import { motion } from 'framer-motion';
import ButtonFull from '../../components/ButtonFull/ButtonFull';
import ArticleThumb from '../../components/Articles/ArticleThumb/ArticleThumb';
import articleList from '../../content/articleList';
import styles from './Blog.module.css';

const Blog = ({location, theme, modalOpen, open, close}) => {
  const handleReadMore = (blogArticle) => modalOpen ? close() : open(blogArticle);

  /**
   * Handles blog article direct routes
   */
  useEffect(() => {
    if(location.search.includes('?')) {
      const href = window.location.href.split('?')[0];
      const id = window.location.href.split('?')[1];
      const article = articleList.find(x => x.id === Number(id))
      if(article) setTimeout(() => open(article.article), 1000);
      window.location.href = href;
    }
  }, [location, open])

  return (
    <motion.div
      className={`${styles.blogWrapper} text`}
      initial={{ y: 1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 1 }}
    >
      <div className={`${styles.blog} mx-auto`}>
        <h3 className={`${styles.h3} fw-light my-5 py-5 text-center`}>
          Welcome to my blog...
        </h3>
      </div>

      <div className={`${styles.articles} bgContrast`}>
        <div className={`${styles.row} row container-fluid mx-auto`}>
          <ArticleThumb
            articleList={articleList}
            onClick={handleReadMore}
            theme={theme}
          />
        </div>
      </div>
      
      <div className={`${styles.moreArticles} text-center fs-6 fw-lighter textGray`}>
        Click below to read more articles about my coding journey.
        <motion.div
          className={`${styles.buttonWrapper} d-flex justify-content-center my-5`}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.125, duration: 0.5 }}
        >
          <ButtonFull
            type={`a`}
            theme={theme}
            to={`https://codetracklift.github.io/codebypete/pages/blog.html`}
            text={`MORE ARTICLES`}
          />
        </motion.div>
      </div>
    </motion.div>
  )
}

export default Blog;
