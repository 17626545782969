const recommendArray = [
  {
    title: 'Key team member',
    text: 'Pete is a shining star, and will be a key member in any team you put him in. His technical skill has enabled him to contribute to our codebase in record time. He also goes the additional mile in documenting all his work.',
    author: 'Patrick A., Director of Engineering',
    company: '@Forbes Media',
  },
  {
    title: 'Relentlessly curious',
    text: 'Pete was a stand-out student during his MIT xPro cohort. He is driven, professional, and relentlessly curious, and I am certain he will be an immense asset to the software team he ends up on. Any team would be lucky to have him.',
    author: 'Tim K., Developer & Sr Instructor',
    company: '@Pluralsight',
  },
  {
    title: 'Fantastic developer',
    text: 'Pete is enthusiastic about his craft and eager to share new ideas. He responds promptly and works through problems until they are resolved. He is a very skilled developer who can think critically and communicate clearly.',
    author: 'Gabriel Z., Software Engineer',
    company: '@Forbes Media',
  },
  {
    title: '10 out of 10',
    text: 'Pete is a stellar coworker! I had the pleasure of working with Pete for 9 years. Pete is very self sufficient and resourceful. He is detail oriented and communicates very well. 10 out of 10, would work with Pete again!',
    author: 'Kurtis O., Sr Systems Administrator',
    company: '@Context365',
  },
  {
    title: 'Great communicator',
    text: 'Pete has been a pleasure to work with. He is a great communicator with attention to detail. He is hungry to improve as a developer, which can be seen by all of the projects he works on to solidify and expand his skill set.',
    author: 'Ben W., Sr Software Engineer',
    company: '@Forbes Media',
  },
  {
    title: 'Exceptionally dedicated',
    text: 'Pete is knowledgeable, articulate and a pleasure to work with. He is a quick learner and ready to work on anything to achieve team goals. Pete has an eye for detail, demonstrates teamwork, and the ability to achieve goals.',
    author: 'Som G., Engineering Manager',
    company: '@Forbes Media',
  },
  {
    title: 'Knowledgeable, professional',
    text: 'Pete is responsive and polite to answer all my questions.  We collaborated and designed a great, fully functional, easy-to-use web page for my photography business. Thanks so much for all your help and expertise!',
    author: 'Lauren N., Owner',
    company: '@LMNOPhoto.biz',
  },
]

export default recommendArray;
