import article1 from './articles/article1';
import article2 from './articles/article2';
import article3 from './articles/article3';
import article4 from './articles/article4';
import article5 from './articles/article5';
import article6 from './articles/article6';

const articleList = [
  {
    id: 6,
    article: article6
  },
  {
    id: 5,
    article: article5
  },
  {
    id: 4,
    article: article4
  },
  {
    id: 3,
    article: article3
  },
  {
    id: 2,
    article: article2
  },
  {
    id: 1,
    article: article1
  },
];

export default articleList;
