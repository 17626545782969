import ArticleFooter from '../../components/Articles/ArticleFooter/ArticleFooter';
import ArticleImage from '../../components/Articles/ArticleImage/ArticleImage';
import findYourFire from '../../assets/articles/findYourFire.gif';
import aspen from '../../assets/articles/aspen.jpg';
import emeritus from '../../assets/articles/emeritus.jpg';

const pStyles = {
  lineHeight: '2rem',
}

const aStyles = {
  fontColor: 'cornflowerblue',
}

const linkedin = `https://www.linkedin.com/feed/update/urn:li:activity:6983089412762935296/`;
const twitter = ``;
const urlArticle = `https://emeritus.org/blog/learner-story-coding-bootcamp-online-pete-chu/`;
const urlGreg = `https://www.linkedin.com/in/greglindeman/`;
const urlTim = `https://www.linkedin.com/in/timmkellogg/`;
const urlPatrick = `https://www.linkedin.com/in/paandre/`;
const urlManasa = `https://www.linkedin.com/in/manasa-ramakrishnan-4b664147/`;
const urlEmeritus = `https://emeritus.org`;

const article5 =  {
  title: 'A Long Overdue Change',
  description: `Emeritus interviewed me about my coding journey, and how I transitioned from pharmaceutical engineering to software development.  This article was authored by Manasa Ramakrishnan.`,
  image: findYourFire,
  alt: 'This article summarizes an Emeritus interview about my coding journey, and how I transitioned from a twenty year career in pharmaceutical engineering into software development.',
  date: 'Oct 4, 2022',
  updated: '',
  html: [
    <p className={`fw-bold`} style={pStyles}>Greetings!</p>,
    <p style={pStyles}>The article below is published on Emeritus.org and was authored by <a href={urlManasa} target='_blank' rel='noreferrer'>Manasa Ramakrishnan</a>. This article summarizes an Emeritus interview about my coding journey. Please see this <a href={urlArticle} target='_blank' rel='noreferrer' style={aStyles}>link</a> for the original article on Emeritus.org.</p>,
    <hr />,
    <ArticleImage
      src={emeritus}
      alt={`Pete was interviewed by Emeritus after successfully completing his full stack coding bootcamp with a 4.0 GPA.  Pete also secured a paid internship with Forbes Media.`}
    />,
    <hr/>,
    <p className={`fw-bold`} style={pStyles}>After 20 Years, Pete Chu Switched to His Dream Career Path. Find Out How?</p>,
    <p style={pStyles}>He built his first computer at 14. Needless to say, technology was Pete Chu’s first love, but life had other plans for him, taking him to a career in life sciences. Though he excelled in this career for 20 years, there was a void that could only be filled with lines of code. After two decades in the pharmaceutical industry, Pete decided to follow his heart, much to his family’s chagrin and concern. But sheer grit and MIT xPRO’s Professional Certificate in Coding helped him pursue a career in coding and make the career shift at 40 most of us shy away from doing. Join us as we talk to him about his big career switch and the decision to pursue a coding bootcamp online. </p>,
    <p className={`fw-bold`} style={pStyles}>Career Switch at 40! Sounds Impossible, Doesn’t It?</p>,
    <p style={pStyles}>“I hail from a family of doctors and those in the pharmaceutical industry and had a rather conventional career path chalked out for me by my loved ones. Needless to say, my family had a certain set of expectations from me as I grew up. I was guided in that direction and ended up completing a life science degree too.”</p>,
    <p style={pStyles}>However, Pete was always fascinated by computers. He fondly recalls being a founding member of his school’s computer club and building three computers by the time he graduated high school!</p>,
    <p style={pStyles}>When Pete made peace with computer science not being his future, he was understandably disheartened. He entered the pharmaceutical industry as a Validation Engineer and moved on to become a Quality Engineer over the next twenty years. Though Pete was very good at his job, his work didn’t satisfy his intellectual curiosity. This dissatisfaction pushed him to rethink his career path at the age of 43!</p>,
    <p className={`fw-bold`} style={pStyles}>Can Career and Passion Go Hand-in-Hand?</p>,
    <p style={pStyles}>Over time, Pete’s love for technology and coding never wore off. In fact, every opportunity he got, he tried to learn something new, code, and build small things. “It wasn’t for any monetary gain, but it was the only way I could keep my passion alive,” Pete explains. </p>,
    <p style={pStyles}>He would have continued to believe that a career and passion cannot go hand-in-hand if he hadn’t come across a coding bootcamp online in December 2021. For Pete, the thought of taking up another four-year degree and an education loan was terrifying. This prevented him from considering a career switch before this. But, now he had the opportunity to learn all the necessary coding skills and make a career switch, in a matter of months! It was a massive decision because his pharmaceutical job was going great and a retirement path was set.</p>,
    <p style={pStyles}>He seized the opportunity and signed up for MIT xPRO’s Professional Certificate in Coding Bootcamp online. And the rest, as they say, is history!</p>,
    <p className={`fw-bold`} style={pStyles}>How to Prepare for a Career Change?</p>,
    <p style={pStyles}>Before taking the enormous career plunge, Pete first decided to test his coding aptitude. He found some free online bootcamps and signed up. “It was an exciting phase and I stayed up every night, trying to learn more. This helped me decide that I did not want to just code on the side, I actually wanted to switch to this career path.” </p>,
    <p style={pStyles}>However, he had a lot of catching up to do, hence he decided to take up the full-time bootcamp and give it his 100%. If you are planning to switch careers, here are a few steps to consider:</p>,
    <ol>
      <li>Evaluate your current role and the path ahead</li>
      <li>Check where your skills and passions lie</li>
      <li>Keep upskilling and learning on the side</li>
      <li>Analyze your financial situation</li>
      <li>Make a list of potential career paths</li>
      <li>Research job listings</li>
      <li>Switch to a new path and role only if you see growth potential</li>
    </ol>,
    <p style={pStyles}>Pete too took a step back and followed all the above steps. Most importantly, he evaluated his financial situation before taking up the full-time coding bootcamp online. Fortunately, he had saved enough to keep himself and his family sustained during the learning phase. Mentally and financially, he was ready to finally take the plunge!</p>,
    <p className={`fw-bold`} style={pStyles}>MIT xPRO’s Professional Certificate in Coding</p>,
    <p style={pStyles}>The four-month bootcamp program offered by MIT xPRO is one of the most intensive programs in the market. Learners are expected to dedicate 35-45 hours every week. Pete, however, had to spend a lot more time to stay ahead of the curve! The course is designed in such a way that participants can become full-stack developers in just 16 weeks. For six weeks before the bootcamp began, Pete prepared vigorously, and this preparation helped him excel!</p>,
    <p style={pStyles}>“Our cohort created a Discord channel, where we could share our progression and struggles. It helped tremendously to have a group of like-minded people on the same journey,” he says.</p>,
    <p className={`fw-bold`} style={pStyles}>How Did the MIT xPRO Coding Bootcamp Online Help Bridge the Gap Between Conceptual Understanding and Real-world Application?</p>,
    <p style={pStyles}>When individuals teach themselves how to code there is always a massive gap between programming concepts and final delivered applications. Pete faced this problem too, as a self-taught coder. The MIT xPRO’s Professional Certificate in Coding bootcamp online helped bridge this gap! At the end of the program, he knew the tools needed to solve a problem and what the expected end result should be.</p>,
    <p style={pStyles}>The program helped Pete build a strong foundation and learn the latest skills necessary to excel in the corporate world. The three aspects that stood out the most to him were:</p>,
    <ol>
      <li>Career coaching experts, especially because they helped him land an internship with Forbes</li>
      <li>The MIT Brand and the intensive course curriculum, mainly the way it was presented by Ivy League faculty</li>
      <li>The interesting and unique problem-solving approach that helped make real-life connections easily</li>
    </ol>,
    <p className={`fw-bold`} style={pStyles}>Benefits of an Internship During a Career Transition</p>,
    <p style={pStyles}>During a coding bootcamp online, learners pick up new tools, build apps or websites, and learn to debug. But, during an internship, they implement everything they’ve learned! In essence, internships are a great segue before diving into a new career path. </p>,
    <p style={pStyles}>Pete also got an exciting internship at Forbes after completing the MIT xPRO Professional Certificate in Coding bootcamp online. “Coding is so much more than just writing code. It is a form of communication. I joined the Forbes front-end engineering team during the internship. A portion of the codebase was migrating from Vanilla Javascript to React. My role was to write production-level code to create reusable components, generate unit test suites, and fix bugs.”</p>,
    <p style={pStyles}>At the end of the internship, Pete even got a unique opportunity to generate a report that was presented to the senior management about web performance testing. After two decades of professional experience in the pharmaceutical space, generating documentation and summarizing data was second nature for Pete. This helped him realize how he could leverage his translatable skills and be more confident in applying for coding roles in a competitive job market. </p>,
    <p style={pStyles}>“I would also like to give a special shout out to <a href={urlGreg} target='_blank' rel='noreferrer'>Greg Lindeman</a> and <a href={urlTim} target='_blank' rel='noreferrer'>Tim Kellogg</a>, Emeritus Learning Facilitators, for supporting me during the bootcamp and <a href={urlPatrick} target='_blank' rel='noreferrer'>Patrick Andre</a>, Director of Engineering at Forbes for making my internship highly memorable.”</p>,
    <p className={`fw-bold`} style={pStyles}>Relying on Transferable Skills to Make a Career Change</p>,
    <ArticleImage
      src={aspen}
      alt={`Pete standing on a mountain top in Aspen, Colorado.  Pete loves to snowboard, it's one of his winter hobbies.`}
    />,  
    <p style={pStyles}>Before switching to a new career path, it is important to list out, evaluate, and hone both soft and hard skills that are easily transferable. And this is exactly what Pete did too!</p>,
    <p style={pStyles}>“Life science and coding are completely different career paths. But, there are a lot of skills that I picked up over the years that helped me smoothly transition,” he says. Here are the top three transferable skills that Pete relied on during his career move:</p>,
    <p className={`fw-bold`} style={pStyles}>Communication</p>,
    <p style={pStyles}>Communication is of utmost importance in every field, especially coding. To write code and handcraft solutions, it is important to understand the audience. Especially, the terms they use, and the problems they face. As a strong and effective communicator, Pete believed in the power of this skill and honed it during his pharmaceutical tenure.</p>,
    <p className={`fw-bold`} style={pStyles}>Root Cause Analysis and Problem-solving</p>,
    <p style={pStyles}>Pete enjoys solving problems and finding the root cause behind issues. During his pharma days too, this was a huge part of his role, at various stages. This skill set is now helping him as a coder: to write better code, spot mistakes easily, and fix them before it’s too late!</p>,
    <p className={`fw-bold`} style={pStyles}>Maintaining the Right Attitude</p>,
    <p style={pStyles}>During Pete’s tenure as a Validation and Quality Engineer, he faced a fair share of work-related problems. As a team leader, it was his responsibility to keep calm, understand the issue at hand, brainstorm, and fix the problem. He would always remind himself and the team to look at the positive aspects of every problem. This helped them remain optimistic and enthusiastic. This is a transferable skill that is helping him as a coder as well. </p>,
    <p style={pStyles}>“When the code I have written isn’t working as expected, I take a step back and make it a point to understand the error message and talk myself through any abstract portions of code. We call this ‘rubber ducking’, and it helps me debug my code,” says Pete.</p>,
    <p className={`fw-bold`} style={pStyles}>The Rising Importance of Lifelong Learning</p>,
    <p style={pStyles}>This is not the first time Pete has tried to switch to the world of technology. Over his 20-year-tenure, he has tried and failed multiple times. Though he was a self-taught coder and kept his skills up to date, his resume was never getting noticed in the pile. This time, however, completing the MIT xPRO certification helped him build a network, learn new skills, and connect with potential employers. “For twenty years I had been on the other side of the interview table. Learning all these new-age interview skills was refreshing and interesting,” says Pete. An added advantage of the program is the career coaching service offered by Emeritus. The three main areas of support are:</p>,
    <ol>
      <li>Resume review </li>
      <li>Internship and job applications</li>
      <li>Interview preparation </li>
    </ol>,
    <p style={pStyles}>“Learning can never stop and skills have a very short shelf life. So, I am going to try to learn continuously; maybe through online courses, webinars, or workshops. I have several interviews lined up and hope to find a great job soon,” he says. Explore the MIT xPRO ​​Professional Certificate in Coding bootcamp here. To know more about Emeritus and the top coding courses offered, visit <a href={urlEmeritus} target='_blank' rel='noreferrer'>emeritus.org</a>.</p>,
    <p style={pStyles}>Authored by <a href={urlManasa} target='_blank' rel='noreferrer'>Manasa Ramakrishnan</a></p>,
    <ArticleFooter
      linkedin={linkedin}
      twitter={twitter} 
    />,
  ],
};

export default article5;
