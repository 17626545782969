import styles from './ScrollDown.module.css';

const ScrollDown = () => {
  
  const scrollDown = () => {
    setTimeout(() => document.querySelector(`#spacer`).scrollIntoView({ block: "start", behavior: "smooth" }));
  }

  return (
    <div className={`${styles.wrapper}`} onClick={() => scrollDown()}>
      <div className={`${styles.scrolldown}`}>
        <div className={`${styles.line}`}></div>
      </div>
    </div>
  )
}

export default ScrollDown;
