import { FaGithub } from 'react-icons/fa';
import { motion } from 'framer-motion';
import ButtonLine from '../../ButtonLine/ButtonLine';
import exchange from '../../../assets/images/exchange.gif';
import styles from './RowXch.module.css';

const RowXch = ({theme}) => {
  return (
    <div className={`${styles.row} row mx-auto`}>
      <h4 className={`${styles.h4} textContrast mb-5 py-5 text-center`}>Full Stack Currency Exchange Bank</h4>
      <div className={`${styles.wrapper} col-md-6`}>
        <h5 className={`${styles.h5} text mx-auto`}>Today's Exchange Rates!</h5>
        <p className={`${styles.p} textContrast`}>
          Integrated into CoinGecko's API to fetch the top crypto and all international currencies.
        </p>
        <p className={`${styles.p} textContrast`}>
          User accounts include the latest cybersecurity features from Bcrypt and JSON Web Tokens.
        </p>
        <p className={`${styles.p} textContrast`}>
          Account creation is streamlined for the busy demo user.
        </p>
        <div className={`${styles.buttonWrapper} m-auto pb-5 mt-4 mb-4`}>
          <ButtonLine
            type={'a'}
            to={'https://xch-mern.herokuapp.com/'}
            text={'DEMO'}
            theme={theme}
          />
          <ButtonLine
            type={'aHtml'}
            to={'https://github.com/codeTrackLift/xch-mern#readme'}
            html={[<FaGithub className={`mb-1 mx-1`} />, 'README']}
            theme={theme}
          />
        </div>
      </div>
      <motion.div
        className={`${styles.col} col-md-6 my-auto p-4`}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.125, duration: 0.5 }}
      >
        <img
          src={exchange}
          alt='Welcome to the MIT xCHANGE! This is a full stack banking application built with the MERN stack. It also has the latest currency exchange rates pulled from the Coin Gecko API.'
          className={`img-fluid dropShadowBig`}
        />
      </motion.div>
    </div>
  )
}

export default RowXch;
