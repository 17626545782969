import ArticleFooter from '../../components/Articles/ArticleFooter/ArticleFooter';
import ArticleImage from '../../components/Articles/ArticleImage/ArticleImage';
import bootcamp from '../../assets/articles/bootcampPrepper.jpg';

const pStyles = {
  lineHeight: '2rem',
}

const aStyles = {
  color: 'cornflowerblue',
}

const linkedin = `https://www.linkedin.com/posts/codebypete_reactjs-nextjs-sass-activity-6949117987429785600-4QwH/`;
const twitter = `https://twitter.com/codeTrackLift/status/1543354561749999619`;

const article2 =  {
  title: 'Reflections from a Bootcamp Prepper (2022 Edition)',
  description: `Have you thought about enrolling in a coding bootcamp to become a software developer? This was a decision that weighed heavily on me about 7 months ago.`,
  image: bootcamp,
  alt: 'Bootcamp prepper, camping on mountain top',
  date: 'Jul 2, 2022',
  updated: 'Sep 4, 2022',
  html: [
    <p className={`fw-bold`} style={pStyles}>Greetings!</p>,
    <p style={pStyles}>Have you thought about enrolling in a coding bootcamp to become a software developer? This was a decision that weighed heavily on me about 7 months ago.</p>,
    <p style={pStyles}>Below is a recap of how I prepared, for myCodingJourney...</p>,
    <p className={`fw-bold`} style={pStyles}>TLDR:</p>,
    <ol className={``}>
      <li>Figure out what you don’t know</li>
      <li>Study the fundamentals</li>
      <li>Learning to code is like learning a language</li>
      <li>Fail forward and do it rapidly</li>
      <li>Learn to use documentation</li>
      <li>Stay ahead of the curriculum</li>
    </ol>,
    <ArticleImage
      src={bootcamp}
      alt={`Bootcamp prepper, camping on mountain top`}
      wide
    />,
    <p style={pStyles}>In Dec of 2021, I enrolled in a full-time full stack coding bootcamp at MIT xPRO, where I would learn to develop and deploy web applications using the MERN stack. Classes started on Wed Feb 9, 2022. This gave me about 6 weeks of preparation time to position myself for success.</p>,
    <p className={`fw-bold`} style={pStyles}>What the heck is a MERN?!?</p>,
    <p style={pStyles}>At that point, I didn't even know what the MERN stack was. So, it seemed logical to start with understanding the curriculum. After reviewing the available documentation and a bit of time on YouTube, I came to understand that the MERN stack consisted of MongoDB, Express, React, and Node. All of which use JavaScript.</p>,
    <p style={pStyles}>Since I had already dabbled with freeCodeCamp (and since it is free), I decided to target completion of the Responsive Web Design and JavaScript Algorithms and Data Structures certificates before bootcamp began. HTML and CSS were a ton of fun and I burned through that in about 2 weeks. JavaScript was a completely different beast…</p>,
    <p style={pStyles}>Everything starts out fine. You learn to define variables and do some arithmetic; it's kinda fun! That all changes once you dive into array methods and objects... ever heard of the phrase 'making your eyes bleed’? 😢</p>,
    <p style={pStyles}>While I was wiping the bloody tears from my eyes, or otherwise had screen fatigue, I shifted my efforts to learning how to learn. I picked up an audiobook called Ultralearning (available in hardcopy too) and scoured the YouTubes for any and all bootcamp related videos.</p>,
    <p className={`fw-bold`} style={pStyles}>My takeaways:</p>,
    <ol className={``}>
      <li>Growth mindest vs fixed mindset</li>
      <li>Immerse yourself and build things</li>
      <li>Be resourceful, learn to use documentation</li>
    </ol>,
    <p style={pStyles}>Tue Feb 8, 2022: I was on a mini vacation with some friends. A last little trip before locking myself in my office for the next 5 months. At this point my biggest coding accomplishments included a JavaScript drum set calculator (yes, now you CAN practice your rhythm while performing mathematical operations), a landingpage for a local photographer, and the aforementioned freeCodeCamp certificates.</p>,
    <p className={`fw-bold`} style={pStyles}>Welcome to MIT xPRO!</p>,
    <p style={pStyles}>This course is entirely online, and you are greeted by MIT Professors Williams and Abel, both seem very knowledgeable and well spoken. Their presentation styles are interesting and engaging (keep in mind that this is coming from a 43-year-old wannabe computer nerd). 😉</p>,
    <p style={pStyles}>The first trimester of bootcamp covered JavaScript, HTML and CSS fundamentals, the exact material I had just completed with freeCodeCamp.</p>,
    <p style={pStyles}>Since this wasn’t my first time with these fundamentals, I was able to focus on polishing and refining what I had already learned. But more importantly, this made it easier to pick up on new methods as I had an existing foundation to build upon.

    </p>,
    <p className={`fw-bold`} style={pStyles}>Teaching techniques included:</p>,
    <ol className={``}>
      <li>Code-along videos with the aforementioned professors</li>
      <li>Reading material / documentation</li>
      <li>Next Tech learning platform (IDE with auto-grader)</li>
      <li>office hours with learning facilitators</li>
    </ol>,
    <p style={pStyles}>The code-along videos were purposeful in steering students through the abundant learning options. Each lesson was ultimately connected to one of the projects that you would later build and add to your portfolio.</p>,
    <p style={pStyles}>The Next Tech learning platform reminded me of the freeCodeCamp IDE, except without ‘campfire’ mode (I miss those acoustic guitar keystrokes!). There were occasions where I thought I had completed the assignment as described in the procedure, but the auto-grader would not give me the green checkmark to proceed.</p>,
    <p style={pStyles}>This is where I learned to read Jest unit-tests. At first these cryptic statements were mystifying to look at. However, once I found the Jest documentation, I was able to figure out the patterns and determine that the test wanted me to use a specific id tag (for example).</p>,
    <p className={`fw-bold`} style={pStyles}>Closing out the first trimester...</p>,
    <p style={pStyles}>We were assigned a portfolio project (see <a href='https://codetracklift.github.io/codebypete/pages/blog.html#blog4Button' target='_blank' rel='noreferrer' style={aStyles}>blog[4]</a>), and three mini-projects (see <a href='https://codetracklift.github.io/codebypete/pages/blog.html#blog5Button' target='_blank' rel='noreferrer' style={aStyles}>blog[5]</a> and <a href='https://codetracklift.github.io/codebypete/pages/blog.html#blog6Button' target='_blank' rel='noreferrer' style={aStyles}>blog[6]</a>). These mini-projects demonstrate our ability to manipulate the DOM with JavaScript, and interact with an API to dynamically render data to our web application. It also provided an opportunity to showcase our individual creativity by developing custom styling, additional features, and functionality.</p>,
    <p style={pStyles}>Hope this was helpful, and best of luck to all who are considering a similar journey.</p>,
    <ArticleFooter
      linkedin={linkedin}
      twitter={twitter} 
    />,
  ],
};

export default article2;
