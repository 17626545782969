import { motion } from 'framer-motion';
import styles from './ArticleImage.module.css';

const ArticleImage = ({src, alt, wide}) => {
  return (
    <motion.div className={`w-100 text-center`}
    initial={{ opacity: 0, y: 200 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <img
      src={src}
      alt={alt}
      className={`
        ${ wide ? styles.imgWide : styles.img }
        img-fluid py-5
      `}
    />
  </motion.div>
  )
}

export default ArticleImage;
