import ArticleFooter from '../../components/Articles/ArticleFooter/ArticleFooter';
import ArticleImage from '../../components/Articles/ArticleImage/ArticleImage';
import creative from '../../assets/articles/creativeMind.jpg';

const pStyles = {
  lineHeight: '2rem',
}

// const aStyles = {
//   color: 'cornflowerblue',
// }

const linkedin = `https://www.linkedin.com/posts/codebypete_reactjs-reactdeveloper-javascript-activity-6954544757037527040-wCrT/`;
const twitter = `https://twitter.com/codeTrackLift/status/1548782086252859392`;

const article3 =  {
  title: 'User Interface Experiments',
  description: `Did you ever discover something about yourself that was unexpected? This is what happened when I had the opportunity to make, a 'User Interface Experiment'...`,
  image: creative,
  alt: 'The creative mind working alongside the analytical mind.',
  date: 'Jul 17, 2022',
  updated: 'Sep 4, 2022',
  html: [
    <p className={`fw-bold`} style={pStyles}>Greetings!</p>,
    <p style={pStyles}>Did you ever discover something about yourself that was unexpected?</p>,
    <p style={pStyles}>This is what happened when I had the opportunity to make, a 'User Interface Experiment'...</p>,
    <p className={`fw-bold`} style={pStyles}>TLDR:</p>,
    <ol className={``}>
      <li>Try creating something, you might like it</li>
      <li>Take advantage of opportunities</li>
      <li>Break out of your comfort zone, learn something new</li>
      <li>Share your work, be open to feedback</li>
    </ol>,
    <ArticleImage
      src={creative}
      alt={`The creative mind working alongside the analytical mind`}
      wide
    />,   
    <p className={`fw-bold`} style={pStyles}>A month into the internship...</p>,
    <p style={pStyles}>During a department meeting, the topic of the UI Showcase came up. All the members of the Frontend Engineering team were encouraged to make contributions, as well as recruit developers from other departments.</p>,
    <p style={pStyles}>At this point, I had worked on about dozen Jira tickets across multiple repositories, earned my 'Pull Shark' badge twice over, and was feeling really great about the intern experience overall.</p>,
    <p style={pStyles}>Nonetheless... the thought of creating something that may be 'showcased' at Forbes Media... well, that was still rather intimidating. 😅</p>,
    <p className={`fw-bold`} style={pStyles}>What do I make?</p>,
    <p style={pStyles}>My mind was an empty cup, which can work great for learning and a 'student-mentality.' For creativity... Not so much.</p>,
    <p style={pStyles}>So, I started by establishing an objective. The 'purpose' of my UI Experiment, would be to re-imagine an existing feature from Forbes.com.</p>,
    <p style={pStyles}>I decided to target image carousels, and used my previous 'stock-ticker' project component as a starting point. The first draft was functional, but looked like a website from the early 2000's. 😆</p>,
    <p style={pStyles}>It rendered an array of images in a horizontal scrolling container, and used some JavaScript to automate the scrolling.</p>,
    <p style={pStyles}>That's when I started looking into React animation libraries. To this point, I relied on ScrollMagic, Bootstrap, and native CSS animations to make my projects responsive. I wanted to learn something new, so I chose Framer-Motion.</p>,
    <p style={pStyles}>This library is well supported, has many weekly downloads, and is relatively simple to implement. It's almost as easy as adding 'motion.' to your JSX tags, then defining the desired effect as properties in the opening tag.</p>,
    <p style={pStyles}>Working on Jira tickets taught me a lot, but it was surprising to discover that I missed the creative aspects of developing my own projects.</p>,
    <p className={`fw-bold`} style={pStyles}>Contributing to the UI Showcase</p>,
    <p style={pStyles}>This involves making a pull request, obtaining reviewer approvals, then ensuring the subsequent build and deploy workflows successfully complete.  Next step was to share my UI Experiment.</p>,
    <p style={pStyles}>Staring at the busy Slack channels, it was (again) intimidating to expose my beginner 'mini-project' to a group of professionals. The words, 'not worthy' were sounding loudly in my head.</p>,
    <p style={pStyles}>After making the post, I quickly switched off that Slack channel and decided to work on another ticket (my way to pretend that it never happened 😣).</p>,
    <p className={`fw-bold`} style={pStyles}>Regrets?</p>,
    <p style={pStyles}>The UI experiment was a lot of fun to make, and I learned a new React animation library.</p>,
    <p style={pStyles}>Also learned something about myself... I had always considered myself to be more on the analytical side. Never thought of myself as someone who enjoyed creativity.</p>,
    <p style={pStyles}>Aside from that, I met some new developers in response to sharing the experiment. One of the developers gave me some really great feedback about removing the 'filler text,' which produced an overall cleaner presentation. Thank you for the feedback! 😃</p>,
    <p style={pStyles}>Thank you Forbes for making the opportunity (and experience) possible.</p>,
    <ArticleFooter
      linkedin={linkedin}
      twitter={twitter} 
    />,
  ],
};

export default article3;
